import * as React from "react";

const SvgProvides = (props) => (
  <svg
    id="Provides_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300.29 270.24"
    {...props}
  >
    <defs>
      <style>{".Provides_svg__cls-1{fill:#2d4c6b}"}</style>
    </defs>
    <path
      className="Provides_svg__cls-1"
      d="M44.79 141.47h137.54a25 25 0 0 0 24.93-24.94V87h12.82a13.52 13.52 0 0 1 13.51 13.51v47.18h8.5v-47.16a22 22 0 0 0-22-22h-12.83V51.2a25 25 0 0 0-24.93-24.93H44.79A25 25 0 0 0 19.86 51.2v65.33a25 25 0 0 0 24.93 24.94ZM28.36 51.2a16.45 16.45 0 0 1 16.43-16.43h137.54a16.45 16.45 0 0 1 16.43 16.43v65.33A16.45 16.45 0 0 1 182.33 133H44.79a16.45 16.45 0 0 1-16.43-16.43ZM115.78 206.98h77.13v8.5h-77.13zM105.21 183.08l-44.16-4.54a11.45 11.45 0 0 0-12.61 11.39v3.78H21.78a4.26 4.26 0 0 0 0 8.51h26.66v23.5H21.78a4.26 4.26 0 0 0 0 8.51h26.81a11.38 11.38 0 0 0 11.3 9.77 11.12 11.12 0 0 0 1.16-.06l44.16-4.53A11.42 11.42 0 0 0 115.49 228v-33.54a11.41 11.41 0 0 0-10.28-11.38ZM107 228a2.92 2.92 0 0 1-2.64 2.92l-44.16 4.53a2.94 2.94 0 0 1-3.2-2.92v-42.6a2.93 2.93 0 0 1 2.87-2.93h.31l44.17 4.54a2.92 2.92 0 0 1 2.63 2.92Z"
    />
    <circle className="Provides_svg__cls-1" cx={66.01} cy={66.55} r={9.54} />
    <circle className="Provides_svg__cls-1" cx={66.01} cy={101.19} r={9.54} />
    <circle className="Provides_svg__cls-1" cx={113.56} cy={66.55} r={9.54} />
    <circle className="Provides_svg__cls-1" cx={113.56} cy={101.19} r={9.54} />
    <circle
      className="Provides_svg__cls-1"
      cx={161.11}
      cy={66.55}
      r={9.54}
      transform="rotate(-58.28 161.118 66.547)"
    />
    <circle
      className="Provides_svg__cls-1"
      cx={161.11}
      cy={101.19}
      r={9.54}
      transform="rotate(-67.5 161.109 101.188)"
    />
    <path
      className="Provides_svg__cls-1"
      d="M278.55 183.47H264.5V158.8h-53.33v24.67h-14a4.21 4.21 0 0 0-3 7.18L232.48 229a7.58 7.58 0 0 0 10.72 0l38.32-38.33a4.2 4.2 0 0 0-2.97-7.2Z"
    />
  </svg>
);

export default SvgProvides;
