import * as React from "react";

const SvgEconomyData = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 3710 3710"
    {...props}
  >
    <defs>
      <linearGradient
        id="economyData_svg__linear-gradient"
        x1={2229.95}
        y1={892.23}
        x2={1890.63}
        y2={1001.13}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fcb148" />
        <stop offset={0.05} stopColor="#fdba46" />
        <stop offset={0.14} stopColor="#ffc244" />
        <stop offset={0.32} stopColor="#ffc444" />
        <stop offset={0.48} stopColor="#fdb946" />
        <stop offset={0.78} stopColor="#f99c4d" />
        <stop offset={0.87} stopColor="#f8924f" />
        <stop offset={1} stopColor="#f8924f" />
      </linearGradient>
      <linearGradient
        id="economyData_svg__linear-gradient-2"
        x1={2685.68}
        y1={1382.69}
        x2={2426.22}
        y2={1465.96}
        xlinkHref="#economyData_svg__linear-gradient"
      />
      <style>
        {
          ".economyData_svg__cls-2{fill:#2d4c6b}.economyData_svg__cls-8{opacity:.2}.economyData_svg__cls-7{fill:#ff9c01}.economyData_svg__cls-8{fill:#b55e00}"
        }
      </style>
    </defs>
    <g id="economyData_svg__Illustration">
      <path
        d="M2987.83 1047.81c-592.45-732.66-1995-773.61-2407.46-192.5-227.24 320.19-132.28 798.82-85.43 1035 86.22 434.57 298.83 714.35 345.26 773.69 112.65 144 386.27 493.79 798 573.74C2341.6 3374.36 3219.33 2678.78 3268 1894c28-452.43-228.95-782.85-280.17-846.19Z"
        style={{
          fill: "#e7eaef",
        }}
      />
      <path
        className="economyData_svg__cls-2"
        d="m2979.87 2286.61-851.15 797.24C1881.47 2274 817.11 2229.45 569.86 1419.57L1421 622.34c247.26 809.87 1311.62 854.4 1558.87 1664.27Z"
      />
      <path
        d="M2160.38 2935.25C1876.66 2238.83 948.64 2145.9 664.92 1449.48l724.43-678.54c283.72 696.42 1211.74 789.35 1495.46 1485.77Z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        className="economyData_svg__cls-2"
        d="M1480.25 919.41c-3.19 3-6.19 5.59-9.48 8.25l-.85.68c-37.65 30.35-81.77 41.23-121.5 32.12-34.73-7.91-65.93-31.24-86.13-70.51a185.3 185.3 0 0 1-8.06-18.23c-9.29-24.13-14.09-51.08-15-79.12L1421 622.34a1029.06 1029.06 0 0 0 104.58 234.15c-9.68 22.24-26.21 45.02-45.33 62.92ZM738.92 1613.78c-19.9 18.65-40.64 30.13-65.16 38.83a1029.34 1029.34 0 0 1-103.9-233l186-174.22c12.79 16.91 24.15 37.72 33.44 61.85q3.69 9.57 6.77 19.43c30.68 97.86 16.27 211.24-49.65 279.72-.23.26-.5.52-.77.8-2.13 2.21-4.51 4.51-6.73 6.59ZM2979.87 2286.61l-181.78 170.27c-12.79-16.92-24.16-37.72-33.45-61.85q-3.67-9.57-6.77-19.43c-30.35-96.86-16.83-208.66 47.38-277.36 3-3.22 5.53-5.82 8.72-8.8 19.11-17.9 41.45-31.52 61.32-37a1029.06 1029.06 0 0 1 104.58 234.17ZM2314.73 2909.63l-186 174.22a1029.34 1029.34 0 0 0-103.9-233c13.5-26.91 27.91-48.35 47.83-67 2.22-2.07 4.66-4.28 7-6.23.29-.23.56-.49.83-.7 35.36-29.74 76.84-42.07 115.21-36.54 38.53 5.59 73.71 29.37 95.73 72.19a183.2 183.2 0 0 1 8.19 18.1c9.26 24.08 14.19 50.92 15.11 78.96ZM2062.69 2047.4c-135.87 127.26-371.29 140.07-533.88 36.16s-176.74-298.36-39.66-426.76c135.87-127.25 371.29-140.06 533.88-36.15s176.74 298.35 39.66 426.75Z"
      />
      <path
        d="m2131.49 3081.26-2.77 2.59C1881.47 2274 817.11 2229.45 569.86 1419.57L1403 639.19a1124.93 1124.93 0 0 1-11.1 138 1052.43 1052.43 0 0 1-43.49 183.28c-24.27 72.89-58.9 151.6-108.73 237.36-246.84 423.63-103.2 572.61 157.14 684.52 68.92 30.4 145.43 58.82 222.75 91.3 34 14.31 68.13 29.8 101.86 46.5 66 32.77 130.47 70.29 189.32 113.19 201.88 141.5 322.89 360.26 284.92 607a702 702 0 0 1-33.55 135.94 365.18 365.18 0 0 0-10.86 37.33c-11.32 47.53-16.39 103.81-19.77 167.65Z"
        style={{
          opacity: 0.2,
          fill: "#2d4c6b",
        }}
      />
      <path
        transform="rotate(-42.25 1253.929 1277.648)"
        style={{
          fill: "none",
        }}
        d="M523.26 889.04h1461.61v777.35H523.26z"
      />
      <path
        d="M2620.59 879.92c0 157.41-123.09 281.25-280.41 276.41-163.73-5-301.43-146.43-301.43-315.64s137.7-292.05 301.43-275c157.32 16.38 280.41 156.81 280.41 314.23Z"
        style={{
          fill: "url(#economyData_svg__linear-gradient)",
        }}
      />
      <path
        className="economyData_svg__cls-7"
        d="M2663.1 866.34c0 157.42-123.09 281.25-280.41 276.42-163.73-5-301.43-146.43-301.43-315.64s137.7-292 301.43-275C2540 568.5 2663.1 708.93 2663.1 866.34Z"
      />
      <path
        className="economyData_svg__cls-8"
        d="M2335.78 900.55c8.76-86.5 88.25-123.12 77.88-202.69-6.39-49-43.56-90.37-85-122.64-125.77 13.19-223 117.33-223 253.54 0 155.54 126.38 285.88 277.08 290.94a261.9 261.9 0 0 0 42.82-2.09c-59.9-59.56-97.31-142.74-89.78-217.06Z"
      />
      <path
        d="M2984.37 1373.28c0 120.37-94.12 215-214.42 211.36-125.19-3.85-230.48-112-230.48-241.35S2644.76 1120 2770 1133c120.25 12.54 214.37 119.91 214.37 240.28Z"
        style={{
          fill: "url(#economyData_svg__linear-gradient-2)",
        }}
      />
      <path
        className="economyData_svg__cls-7"
        d="M3016.87 1362.9c0 120.37-94.12 215-214.41 211.36-125.19-3.85-230.48-112-230.48-241.35s105.29-223.31 230.48-210.27c120.29 12.52 214.41 119.9 214.41 240.26Z"
      />
      <path
        className="economyData_svg__cls-8"
        d="M2766.59 1389.06c6.7-66.14 67.48-94.14 59.55-155-4.89-37.5-33.31-69.1-65-93.78-96.14 10.11-170.54 89.72-170.54 193.88 0 118.94 96.63 218.6 211.86 222.46a199.83 199.83 0 0 0 32.74-1.59c-45.76-45.54-74.36-109.15-68.61-165.97Z"
      />
    </g>
  </svg>
);

export default SvgEconomyData;
