import React from "react";
import { useTranslation } from "react-i18next";

const Functionality = ({ list, boxSize }) => {
  const { t } = useTranslation();
  return (
    <div className="wrapper">
      {list.map((item, index) => (
        <div
          key={index}
          className={
            boxSize === "large"
              ? "wrapper-content larger-box"
              : "wrapper-content"
          }
        >
          {item.imageUrl && <img src={item.imageUrl} alt="" />}
          {item.svgIcon && <item.svgIcon />}
          {item.number && (
            <div className="wrapper-number">
              <span>{item.number}</span>
            </div>
          )}
          <h4>{t(item.title)}</h4>
        </div>
      ))}
    </div>
  );
};

export default Functionality;
