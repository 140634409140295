import React, { useState, useEffect } from "react";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { animateScroll as scroll } from "react-scroll";
import "../css/bottomUp.css";

let BottomUp = () => {
  const [height, setHeight] = useState(window.scrollY);

  useEffect(() => {
    let handleResize = () => {
      setHeight(window.scrollY);
    };
    window.addEventListener("scroll", handleResize);

    return () => {
      window.removeEventListener("scroll", handleResize);
      setHeight(null);
    };
  }, []);

  return (
    <div className="bottom-up">
      {height > 400 && (
        <KeyboardArrowUpRoundedIcon
          className="bottom-up__svg-icon"
          onClick={() => {
            scroll.scrollToTop();
          }}
        />
      )}
    </div>
  );
};

export default BottomUp;
