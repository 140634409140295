import * as React from "react";

const SvgGeneralInformation = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3710 3710" {...props}>
    <defs>
      <linearGradient
        id="generalInformation_svg__linear-gradient"
        x1={1885.03}
        y1={2025.82}
        x2={1977.19}
        y2={3335.39}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#2d4c6b" />
        <stop offset={1} stopColor="#21aad4" />
      </linearGradient>
      <linearGradient
        id="generalInformation_svg__linear-gradient-2"
        x1={1067.55}
        y1={1747.04}
        x2={1280.85}
        y2={1747.04}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.01} stopColor="#2d4c6b" />
        <stop offset={1} stopColor="#21aad4" />
      </linearGradient>
      <style>
        {
          ".generalInformation_svg__cls-2{fill:#91b3fa}.generalInformation_svg__cls-6,.generalInformation_svg__cls-7{fill:#fff}.generalInformation_svg__cls-6{opacity:.6}"
        }
      </style>
    </defs>
    <g id="generalInformation_svg__Illustration">
      <path
        d="M3024.32 2861.15c-57.22 60.25-111.37 99.1-154.16 129.8-502.89 360.83-1097.94 255.65-1224.41 230C1459 3183 1098 3109.66 837 2802.75 442.18 2338.41 503.66 1606.87 809.94 1124c56-88.33 351.94-578.59 953.41-657.21 364-47.58 788.3 48.38 1095.57 305.82 645 540.33 599.08 1631.98 165.4 2088.54Z"
        style={{
          fill: "#e7eaef",
        }}
      />
      <path
        className="generalInformation_svg__cls-2"
        d="M1091.82 2841.46s-31.86-220.89 216.64-237.88 1461.31 25.48 1461.31 25.48-51 246.39 0 352.59c-233.64-2.13-1584.5 0-1584.5 0s-101.38-48.65-93.45-140.19Z"
      />
      <path
        d="M2793.13 2955.35H1263.86c-31.15 0-150.09 7.76-147.26-125.34 1.36-64 35.43-103.4 70.46-128.39 32.46-23.16 71.39-34.44 111.27-34.44h1392.85c130.48 0 105.4-174 105.4-174L1312 2529.7s-220.89 130.77-244.45 264.15a239.46 239.46 0 0 0 0 85.4c13.48 72.26 76.7 125.3 150.2 125.3h1575.38c14.08 0 25.49-10.52 25.49-24.61s-11.41-24.59-25.49-24.59Z"
        style={{
          fill: "url(#generalInformation_svg__linear-gradient)",
        }}
      />
      <path
        d="M2703.17 702.72H1278.42v1932.83h1424.75c54.38 0 93.41-50.57 93.41-105V798.94c0-54.38-39.03-96.22-93.41-96.22Z"
        style={{
          fill: "#2d4c6b",
        }}
      />
      <path
        d="M1280.85 700.48s-229.39 45.7-212.4 222.5-.9 1870.61-.9 1870.61 68.87-164.53 213.3-164.53Z"
        style={{
          fill: "url(#generalInformation_svg__linear-gradient-2)",
        }}
      />
      <rect
        className="generalInformation_svg__cls-6"
        x={1499.82}
        y={2691.78}
        width={1117.53}
        height={10.54}
        rx={3.19}
      />
      <path
        className="generalInformation_svg__cls-6"
        d="M2642 2808.62c0 1.76-3 6.16-5 6.16H1344c-2.05 0-9.24-4.4-9.24-6.16v-4.71c0-1.76 7.19.33 9.24.33h1293c2 0 5-2.09 5-.33Z"
      />
      <rect
        className="generalInformation_svg__cls-6"
        x={1844.21}
        y={2899.12}
        width={825.85}
        height={14.06}
        rx={6.37}
      />
      <path
        className="generalInformation_svg__cls-2"
        d="M1331.13 2422.91c-2.78 0-3.51-2.25-3.51-5v-710.16c0-2.78.73-5 3.51-5s3.52 2.26 3.52 5v710.13c0 2.78-.74 5.03-3.52 5.03ZM1331.13 1602c-2.78 0-3.51-2.26-3.51-5v-342.52c0-2.78.73-5 3.51-5s3.52 2.26 3.52 5V1597c0 2.73-.74 5-3.52 5ZM1331.13 957.33c-2.78 0-3.51-2.25-3.51-5V760.92c0-2.79.73-5 3.51-5s3.52 2.25 3.52 5V952.3c0 2.78-.74 5.03-3.52 5.03Z"
      />
      <path
        className="generalInformation_svg__cls-7"
        d="M1982.76 1325c-66.58-1.74-113.56-19.32-141.41-52.67q-41.6-49.78-41.55-122.06t45.26-119.18c30.34-31.4 76.75-47.17 139.6-47.18q95.15 0 145.55 43.58 50.46 43.88 50.54 124.89t-48.6 128.29c-32.22 31.39-82.15 46.1-149.39 44.33ZM1815.45 1445.57h347.76v863.92h-347.76z"
      />
      <path
        className="generalInformation_svg__cls-7"
        d="M1679 2269.26h533.16a1.92 1.92 0 0 1 1.92 1.92v36.73a1.58 1.58 0 0 1-1.58 1.58h-534.18a1.83 1.83 0 0 1-1.83-1.83v-35.86a2.54 2.54 0 0 1 2.51-2.54ZM1627.31 1445.57h534.14a1.77 1.77 0 0 1 1.77 1.77v38.46H1627.7a2.1 2.1 0 0 1-2.1-2.1v-36.41a1.71 1.71 0 0 1 1.71-1.72Z"
      />
    </g>
  </svg>
);

export default SvgGeneralInformation;
