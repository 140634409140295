import React from "react";
import "../css/contact.css";
import logo from "../img/gis_logo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { appName } from "../data/staticData";
import SvgLogo from "../icons/Logo";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="contact-container">
        <div className="contact-container__column-1">
          {/*<img src={logo} alt="" />*/}
          <SvgLogo />
          <div className="contact-container__reference">
            <Link to={"/cookies-policy"}>{t("Πολιτική")}</Link>|
            <Link to={"/terms-of-use"}>{t("Όροι Χρήσης")}</Link>|
            <Link to={"/privacy"}>{t("Προσωπικά δεδομένα")}</Link>
          </div>
        </div>
        <div className="contact-container__column-2">
          <h2>{t("Επικοινωνία")}</h2>
          <div>
            <div className="contact-container__details">
              <h4>{t("Θεσσαλονίκη")}</h4>
              <p>
                {t("Διεύθυνση")}:{" "}
                <span>{t("Ναυάρχου Κουντουριώτου 3, Θεσσαλονίκη 546 25")}</span>
              </p>
              <p>
                Email: <span>info@dotsoft.gr</span>
              </p>
              <p>
                {t("Τηλέφωνο")}: <span>+30 2310 500181</span>
              </p>
              <p>
                Fax: <span>+30 2310 551844</span>
              </p>
            </div>
            <div className="contact-container__details">
              <h4>{t("Αθήνα")}</h4>
              <p>
                {t("Διεύθυνση")}:{" "}
                <span>{t("Πριήνης 4, Νέα Σμύρνη 171 22, Αθήνα")}</span>
              </p>
              <p>
                Email: <span>info@dotsoft.gr</span>
              </p>
              <p>
                {t("Τηλέφωνο")}: <span>+30 210 9510200</span>
              </p>
            </div>
            <div className="contact-container__details">
              <h4>{t("Τρίκαλα")}</h4>
              <p>
                {t("Διεύθυνση")}:{" "}
                <span>
                  {t(
                    "GiSeMi HUB – Κόμβος Καινοτομίας & Επιχειρηματικότητας Τρικάλων, Βαλκάνου 6 Τρίκαλα, 421 00"
                  )}
                </span>
              </p>
              <p>
                Email: <span>info@dotsoft.gr</span>
              </p>
              <p>
                {t("Τηλέφωνο")}: <span>+30 24310 73888</span>
              </p>
              {/*<p>*/}
              {/*  Fax: <span>+30 2310 551844</span>*/}
              {/*</p>*/}
            </div>
          </div>

          <div>
            <div className="contact-container__details">
              <h4>{t("Ιωάννινα")}</h4>
              <p>
                {t("Διεύθυνση")}:{" "}
                <span>
                  {t(
                    "Επιστημονικό & Τεχνολογικό Πάρκο Ηπείρου, Πανεπιστημιούπολη, Ιωάννινα 451 10"
                  )}
                </span>
              </p>
              <p>
                Email: <span>info@dotsoft.gr</span>
              </p>
              <p>
                {t("Τηλέφωνο")}: <span>+30 26510 07678</span>
              </p>
              {/*<p>*/}
              {/*  Fax: <span>+30 2310 551844</span>*/}
              {/*</p>*/}
            </div>
            <div className="contact-container__details">
              <h4>{t("Ηράκλειο Κρήτης")}</h4>
              <p>
                {t("Διεύθυνση")}:{" "}
                <span>
                  {t(
                    "Νικ. Πλαστήρα 100, Επιστημονικό & Τεχνολογικό Πάρκο Κρήτης, Ηράκλειο, 700 13"
                  )}
                </span>
              </p>
              <p>
                Email: <span>info@dotsoft.gr</span>
              </p>
              <p>
                {t("Τηλέφωνο")}: <span>+30 2310 500181</span>
              </p>
              {/*<p>*/}
              {/*  Fax: <span>+30 2310 551844</span>*/}
              {/*</p>*/}
            </div>
          </div>
        </div>
      </div>
      <div className="contact-container__copyrights">
        <p>
          © {new Date().getFullYear()} {appName}. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Contact;
