import * as React from "react";

const SvgArchiveAccounts = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3710 3710" {...props}>
    <defs>
      <style>
        {
          ".archiveAccounts_svg__cls-3{fill:#21aad4}.archiveAccounts_svg__cls-4{fill:#2d4c6b}.archiveAccounts_svg__cls-6,.archiveAccounts_svg__cls-8{fill:#fff}.archiveAccounts_svg__cls-8,.archiveAccounts_svg__cls-9{mix-blend-mode:soft-light}.archiveAccounts_svg__cls-8{opacity:.74}"
        }
      </style>
    </defs>
    <g
      style={{
        isolation: "isolate",
      }}
    >
      <g id="archiveAccounts_svg__Illustration">
        <circle
          cx={1804.57}
          cy={1855}
          r={1400}
          style={{
            fill: "#e7eaef",
          }}
        />
        <path
          className="archiveAccounts_svg__cls-3"
          d="m3436.28 2569.61-2813.77-23.16V2615c0 18.6 12.27 34.41 28.9 37.23l795.65 134.94a115.64 115.64 0 0 0 28.54 1.27l1930.1-153.86c17.24-1.37 30.58-17.23 30.58-36.34Z"
        />
        <path
          className="archiveAccounts_svg__cls-4"
          d="M2415.18 2458.53 651.61 2583.89c-31.56 2.24-57.22-21.6-57.22-53.25V1262.51a57.29 57.29 0 0 1 57.22-57.31h1763.57c26 0 47 23.19 47 51.81v1146.37c.02 28.62-21.01 53.3-47 55.15Z"
        />
        <path
          className="archiveAccounts_svg__cls-4"
          d="M2443.3 2458.53 679.73 2583.89c-31.56 2.24-57.22-21.6-57.22-53.25V1262.51a57.29 57.29 0 0 1 57.22-57.31H2443.3c26 0 47 23.19 47 51.81v1146.37c.02 28.62-21 53.3-47 55.15Z"
        />
        <path
          d="M2427.16 1321.27v1011.24c0 23.53-17.35 43.76-38.81 45.2l-328.6 21.77-1189.21 78.86-124.77 8.27c-25.66 1.69-46.54-17.89-46.54-43.72V1332.64a46.93 46.93 0 0 1 46.54-47l622.47-2.58 211-.89 168.08-.69 147.81-.62 493.21-2.06c21.47-.05 38.82 18.94 38.82 42.47Z"
          style={{
            fill: "#ff9c01",
          }}
        />
        <path
          className="archiveAccounts_svg__cls-6"
          d="M1623.8 1239.89c0 11.78-9.08 21.36-20.29 21.39s-20.33-9.51-20.33-21.32 9.11-21.39 20.33-21.4 20.29 9.54 20.29 21.33Z"
        />
        <path
          d="M2018.39 2110.72c-136-73.58-242.85 19.66-346-72.33-62.55-55.77-31.84-97.36-108.8-180.7-85.24-92.31-140.74-61.28-226.41-144.36-108.32-105-54.14-186.77-147.45-262.19-113.19-91.5-235.26-6.81-343-94.56-46.47-37.85-72-92.87-85.28-151.38H656a33.57 33.57 0 0 0-33.48 33.64v1314.53c0 18.58 15 32.57 33.48 31.25l1797.36-128.33c8.82-.63 16.65-5.81 21.66-13.32-131.57-4.82-212.4-49.19-264.07-95-101.09-89.78-83.56-178.26-192.56-237.25Z"
          style={{
            mixBlendMode: "soft-light",
            opacity: 0.48,
            fill: "#fff",
          }}
        />
        <path
          className="archiveAccounts_svg__cls-4"
          d="m2513.59 2452.84-1891.08 132 824.55 139.84a115.63 115.63 0 0 0 28.54 1.26l1960.68-156.29-892.61-115.87a151.27 151.27 0 0 0-30.08-.94Z"
        />
        <path
          className="archiveAccounts_svg__cls-4"
          d="m898.45 2588.11 1574.38-111.49 487.25 62.74-1560.81 119.19-500.82-70.44zM2707.58 485.14l-1396.21-47.53c-46.72-1.59-84.75 35.21-84.75 82.21v1019a82.65 82.65 0 0 0 84.75 83.14l1396.21-32.11c40.47-.93 73.16-36.94 73.16-80.43v-943c0-43.53-32.69-79.9-73.16-81.28Z"
        />
        <path
          className="archiveAccounts_svg__cls-8"
          d="M1253.75 1517.39V541.53c0-43.49 35.16-77.59 78.35-76.18l1357.4 44.44c37.58 1.23 67.94 34.93 67.94 75.28v905.39c0 40.35-30.36 73.72-67.94 74.53l-1357.39 29.45a76.58 76.58 0 0 1-78.36-77.05Z"
        />
        <path
          className="archiveAccounts_svg__cls-3"
          d="m2707.58 485.14-1396.21-47.53c-46.72-1.59-84.75 35.21-84.75 82.21v61l1554.12 42v-56.44c0-43.49-32.69-79.86-73.16-81.24Z"
        />
        <path
          className="archiveAccounts_svg__cls-6"
          d="M1377.32 516.15a26.62 26.62 0 0 1-27.49 26.85 28.64 28.64 0 0 1-27.56-28.52 26.62 26.62 0 0 1 27.56-26.85 28.65 28.65 0 0 1 27.49 28.52ZM1473.27 519.06a26.43 26.43 0 0 1-27.27 26.73 28.43 28.43 0 0 1-27.3-28.38 26.43 26.43 0 0 1 27.3-26.73 28.44 28.44 0 0 1 27.27 28.38ZM1568.34 522a26.25 26.25 0 0 1-27 26.61c-14.94-.44-27.06-13.08-27.06-28.25a26.25 26.25 0 0 1 27.06-26.61c14.93.42 27 13.07 27 28.25Z"
        />
        <path
          className="archiveAccounts_svg__cls-3"
          d="m2695.35 1235.87-1369.95 6.46V839.41l1369.95 20.14v376.32z"
        />
        <path
          className="archiveAccounts_svg__cls-6"
          d="m2695.35 1330.89-1369.95 13.19v-43.27l1369.95-10.33v40.41zM2695.35 1430.03l-1369.95 20.18v-43.26l1369.95-17.33v40.41zM2062.18 752.11l-736.77-14.44v43.26l736.77 12.91v-41.73zM2617.18 762.99v40.57l78.17 1.37v-40.41l-78.17-1.53z"
        />
        <g className="archiveAccounts_svg__cls-9">
          <path
            className="archiveAccounts_svg__cls-6"
            d="m1516.66 940.52-166.87-1.78v-47.85l166.87 2.14v47.49zM2408.14 950.01l-784.94-8.36V894.4l784.94 10.1v45.51zM1785.88 1023.48l-162.68-1.11v-47.25l162.68 1.47v46.89zM2655.54 1029.45l-765.78-5.25v-46.67l765.78 6.96v44.96zM1785.88 1103.58l-162.68-.5v-47.25l162.68.86v46.89zM2655.54 1106.25l-765.78-2.35v-46.66l765.78 4.05v44.96zM1516.66 1183.87l-166.87.12v-47.86l166.87.25v47.49zM2408.14 1183.23l-784.94.56v-47.25l784.94 1.18v45.51z"
          />
        </g>
        <path
          className="archiveAccounts_svg__cls-3"
          d="m3147.21 727-896.38-9.45c-42.39-.45-76.89 35.47-76.89 80.23v1415c0 44.76 34.5 78.67 76.89 75.75l896.38-61.75c38.48-2.65 69.55-39.16 69.55-81.57V804.56c0-42.41-31.07-77.12-69.55-77.56Z"
        />
        <path
          className="archiveAccounts_svg__cls-8"
          d="M2198.56 2191.37V817.89c0-41.43 31.89-74.71 71.09-74.35l860.38 8c35.72.33 64.58 32.49 64.58 71.83v1304.5c0 39.35-28.86 73.19-64.58 75.61l-860.38 58.14c-39.2 2.6-71.09-28.82-71.09-70.25Z"
        />
        <path
          className="archiveAccounts_svg__cls-3"
          d="m3147.21 727-896.38-9.45c-42.39-.45-76.89 35.47-76.89 80.23V856l1042.82 3.69v-55.13c0-42.41-31.07-77.12-69.55-77.56Z"
        />
        <path
          className="archiveAccounts_svg__cls-6"
          d="M3147.52 792.09c0 14.42-11 26-24.51 26s-24.57-11.86-24.57-26.31 11-26.09 24.57-26 24.51 11.89 24.51 26.31Z"
        />
        <path
          className="archiveAccounts_svg__cls-4"
          d="m3135.59 1882.29-872.02 44.12v-826.38l872.02-7.67v789.93z"
        />
        <path
          className="archiveAccounts_svg__cls-6"
          d="m3135.59 1974.93-872.02 48.41v-41.22l872.02-46.58v39.39zM3135.59 2071.58l-872.02 52.87v-41.22l872.02-51.05v39.4zM2933.31 1000.5l-669.74 2.61v41.21l669.74-4v-39.82zM3061.29 1000v39.55l74.3-.44v-39.4l-74.3.29z"
        />
        <g className="archiveAccounts_svg__cls-9">
          <path
            className="archiveAccounts_svg__cls-6"
            d="m2413.57 1182.7-92.64 1.25.15-41.21 92.64-.96-.15 40.92zM2911.47 1176.03l-438.67 5.88.16-40.75 438.69-4.55-.18 39.42zM2563.12 1249.82l-90.59 1.68.16-40.74 90.59-1.4-.16 40.46zM3050.22 1240.78l-429.18 7.96.17-40.29 429.2-6.66-.19 38.99zM2562.84 1318.92l-90.58 2.15.16-40.73 90.58-1.87-.16 40.45zM3049.89 1307.37l-429.13 10.18.17-40.28 429.16-8.88-.2 38.98zM2412.78 1392.33l-92.6 2.67.14-41.17 92.62-2.4-.16 40.9zM2910.53 1377.96 2472 1390.62l.15-40.72 438.56-11.33-.18 39.39zM2562.28 1457.08l-90.55 3.07.16-40.7 90.56-2.81-.17 40.44zM3049.24 1440.52l-429.05 14.59.17-40.26 429.07-13.3-.19 38.97zM2562 1526.13l-90.54 3.54.16-40.69 90.55-3.28-.17 40.43zM3048.91 1507.06l-429 16.8.16-40.25 429.03-15.5-.19 38.95zM2561.73 1595.16l-90.54 4.01.16-40.68 90.54-3.74-.16 40.41zM3048.59 1573.59l-428.97 19.01.17-40.24 428.99-17.72-.19 38.95zM2709.27 1656.87l-88.53 4.37.16-40.22 88.54-4.11-.17 39.96zM3115.16 1636.8l-349.26 17.27.17-39.79 349.29-16.22-.2 38.74zM2411.47 1741.35l-92.55 5.05.14-41.12 92.57-4.77-.16 40.84zM2908.96 1714.19l-438.31 23.93.16-40.67 438.33-22.6-.18 39.34zM2560.89 1802.16l-90.5 5.4.15-40.65 90.51-5.13-.16 40.38zM3047.61 1773.09l-428.84 25.61.17-40.21 428.86-24.32-.19 38.92zM2560.61 1871.12l-90.49 5.87.15-40.64 90.51-5.6-.17 40.37zM3047.28 1839.55l-428.79 27.82.16-40.2 428.82-26.53-.19 38.91z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgArchiveAccounts;
