import * as React from "react";

const SvgBasicFunc02 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 2475 1650"
    {...props}
  >
    <defs>
      <linearGradient
        id="BasicFunc_02_svg__linear-gradient"
        x1={848.34}
        y1={-35.62}
        x2={135.85}
        y2={1819.98}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#53d8ff" />
        <stop offset={1} stopColor="#3840f7" />
      </linearGradient>
      <linearGradient
        id="BasicFunc_02_svg__linear-gradient-2"
        x1={369.3}
        y1={594.55}
        x2={845.85}
        y2={594.55}
        xlinkHref="#BasicFunc_02_svg__linear-gradient"
      />
      <linearGradient
        id="BasicFunc_02_svg__linear-gradient-3"
        x1={414.21}
        y1={282.13}
        x2={755.36}
        y2={282.13}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#dae3fe" />
        <stop offset={1} stopColor="#e9effd" />
      </linearGradient>
      <linearGradient
        id="BasicFunc_02_svg__linear-gradient-4"
        x1={450.42}
        y1={319.36}
        x2={750.85}
        y2={319.36}
        xlinkHref="#BasicFunc_02_svg__linear-gradient-3"
      />
      <linearGradient
        id="BasicFunc_02_svg__linear-gradient-5"
        x1={390.62}
        y1={635.97}
        x2={827.07}
        y2={635.97}
        xlinkHref="#BasicFunc_02_svg__linear-gradient-3"
      />
      <linearGradient
        id="BasicFunc_02_svg__linear-gradient-6"
        x1={395.6}
        y1={405.7}
        x2={817.81}
        y2={405.7}
        xlinkHref="#BasicFunc_02_svg__linear-gradient-3"
      />
      <linearGradient
        id="BasicFunc_02_svg__linear-gradient-7"
        x1={256.08}
        y1={989.68}
        x2={971.12}
        y2={989.68}
        xlinkHref="#BasicFunc_02_svg__linear-gradient-3"
      />
      <linearGradient
        id="BasicFunc_02_svg__linear-gradient-8"
        x1={345.68}
        y1={977.18}
        x2={476.03}
        y2={977.18}
        xlinkHref="#BasicFunc_02_svg__linear-gradient-3"
      />
      <linearGradient
        id="BasicFunc_02_svg__linear-gradient-9"
        x1={672.49}
        y1={646.13}
        x2={609.49}
        y2={779.63}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4042e2" />
        <stop offset={1} stopColor="#4f52ff" />
      </linearGradient>
      <linearGradient
        id="BasicFunc_02_svg__linear-gradient-10"
        x1={548.75}
        y1={987.02}
        x2={548.92}
        y2={987.02}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#09005d" />
        <stop offset={1} stopColor="#1a0f91" />
      </linearGradient>
      <linearGradient
        id="BasicFunc_02_svg__linear-gradient-11"
        x1={548.75}
        y1={1065.88}
        x2={548.92}
        y2={1065.88}
        xlinkHref="#BasicFunc_02_svg__linear-gradient-10"
      />
      <style>
        {
          ".BasicFunc_02_svg__cls-4,.BasicFunc_02_svg__cls-5{fill:#fff}.BasicFunc_02_svg__cls-4{opacity:.6}.BasicFunc_02_svg__cls-5{opacity:.61}.BasicFunc_02_svg__cls-6{fill:#2d4c6b}.BasicFunc_02_svg__cls-7{fill:#91b3fa}.BasicFunc_02_svg__cls-12{fill:#bdd0fb}.BasicFunc_02_svg__cls-14{opacity:.85}.BasicFunc_02_svg__cls-15{fill:#7d97f4}.BasicFunc_02_svg__cls-16{fill:#ff9c01}"
        }
      </style>
    </defs>
    <g id="BasicFunc_02_svg__Illustration">
      <path
        d="M2279.26 1081.41c-33-83.06-131.65-131.08-150.46-218.43-17.6-81.75 41.73-157.21 82.28-230.35 27-48.77 47-102.82 47.53-158.58s-20.34-113.39-63.4-148.82c-56.36-46.38-137.12-46.83-209.38-36.47S1839.9 317.93 1768.66 302c-61.29-13.69-114.45-51.93-174.71-69.58-89.75-26.3-186.8-4.63-273.78 29.77s-169 81.4-258.57 108.46c-119.65 36.17-250.37 34.76-369.22-4-60.08-19.59-117-48.36-177.79-65.57s-128.51-21.89-185.19 6c-68.32 33.71-108.24 110.29-114.09 186.22S233 644.57 264.52 713.9c16.9 37.16 36.32 73.67 45.53 113.45s6.88 84.44-17 117.55c-23 31.93-61.89 48.11-90.75 74.91-60.7 56.34-67.44 155.9-29.2 229.37s112.9 122.53 191.8 147.76 162.71 29.47 245.43 33.53l-.55 14.65L1870 1395.3c101.37-4 207.17-9.22 295.38-59.35s151.3-160.25 113.88-254.54Z"
        style={{
          fill: "#e7eaef",
        }}
      />
      <path
        d="M346.78 944.76A27.21 27.21 0 0 0 374 971.82h471.2a27.2 27.2 0 0 0 27.2-27.21V238.85a27.2 27.2 0 0 0-27.13-27.21l-475-1.27A27.21 27.21 0 0 0 343 237.72Z"
        style={{
          fill: "url(#BasicFunc_02_svg__linear-gradient)",
        }}
      />
      <path
        d="M369.3 273.18a27.62 27.62 0 0 1 27.78-28l421.33 2.59a27.62 27.62 0 0 1 27.44 27.74L842.91 909a27.6 27.6 0 0 1-27.1 27.47l-409.38 7.47a27.61 27.61 0 0 1-28.11-27.22Z"
        style={{
          fill: "url(#BasicFunc_02_svg__linear-gradient-2)",
        }}
      />
      <rect
        className="BasicFunc_02_svg__cls-4"
        x={414.21}
        y={272.42}
        width={341.16}
        height={19.42}
        rx={8.26}
      />
      <path
        className="BasicFunc_02_svg__cls-4"
        d="M450.43 317.22a8.26 8.26 0 0 0 8.09 7.81l283.92 5.17a8.26 8.26 0 0 0 8.41-8.26v-.67a8.26 8.26 0 0 0-8.13-8.26l-283.91-4.5a8.26 8.26 0 0 0-8.38 8.71Z"
      />
      <path
        className="BasicFunc_02_svg__cls-5"
        d="M421.45 920.35a21.67 21.67 0 0 1-21.61-21.23L390.63 381a21.64 21.64 0 0 1 21.19-22l383.34-7.46a21.63 21.63 0 0 1 22 21.2l9.88 520.94a21.61 21.61 0 0 1-21.35 22l-384 4.61Zm374.14-564.77h-.36L411.9 363a17.64 17.64 0 0 0-17.27 18l9.21 518.08a17.68 17.68 0 0 0 17.82 17.3l384-4.61a17.62 17.62 0 0 0 17.4-18l-9.88-520.93a17.61 17.61 0 0 0-17.6-17.28Z"
      />
      <path
        className="BasicFunc_02_svg__cls-5"
        d="M815.81 407.7H397.6a2 2 0 0 1 0-4h418.21a2 2 0 0 1 0 4Z"
      />
      <path
        className="BasicFunc_02_svg__cls-6"
        d="M346.78 944.76A27.21 27.21 0 0 0 374 971.82h471.2a27.2 27.2 0 0 0 27.2-27.21V238.85a27.2 27.2 0 0 0-27.13-27.21l-475-1.27A27.21 27.21 0 0 0 343 237.72Z"
      />
      <path
        className="BasicFunc_02_svg__cls-7"
        d="M369.3 273.18a27.62 27.62 0 0 1 27.78-28l421.33 2.59a27.62 27.62 0 0 1 27.44 27.74L842.91 909a27.6 27.6 0 0 1-27.1 27.47l-409.38 7.47a27.61 27.61 0 0 1-28.11-27.22Z"
      />
      <rect
        x={414.21}
        y={272.42}
        width={341.16}
        height={19.42}
        rx={8.26}
        style={{
          fill: "url(#BasicFunc_02_svg__linear-gradient-3)",
        }}
      />
      <path
        d="M450.43 317.22a8.26 8.26 0 0 0 8.09 7.81l283.92 5.17a8.26 8.26 0 0 0 8.41-8.26v-.67a8.26 8.26 0 0 0-8.13-8.26l-283.91-4.5a8.26 8.26 0 0 0-8.38 8.71Z"
        style={{
          fill: "url(#BasicFunc_02_svg__linear-gradient-4)",
        }}
      />
      <path
        d="M421.45 920.35a21.67 21.67 0 0 1-21.61-21.23L390.63 381a21.64 21.64 0 0 1 21.19-22l383.34-7.46a21.63 21.63 0 0 1 22 21.2l9.88 520.94a21.61 21.61 0 0 1-21.35 22l-384 4.61Zm374.14-564.77h-.36L411.9 363a17.64 17.64 0 0 0-17.27 18l9.21 518.08a17.68 17.68 0 0 0 17.82 17.3l384-4.61a17.62 17.62 0 0 0 17.4-18l-9.88-520.93a17.61 17.61 0 0 0-17.6-17.28Z"
        style={{
          fill: "url(#BasicFunc_02_svg__linear-gradient-5)",
        }}
      />
      <path
        d="M815.81 407.7H397.6a2 2 0 0 1 0-4h418.21a2 2 0 0 1 0 4Z"
        style={{
          fill: "url(#BasicFunc_02_svg__linear-gradient-6)",
        }}
      />
      <path
        className="BasicFunc_02_svg__cls-6"
        d="M1144.79 846.42H673.57a27.2 27.2 0 0 1-27.2-27.2v-455A27.2 27.2 0 0 1 673.5 337l475-1.27a27.21 27.21 0 0 1 27.28 27.35L1172 819.36a27.21 27.21 0 0 1-27.21 27.06Z"
      />
      <path
        className="BasicFunc_02_svg__cls-7"
        d="m1121.69 370.58-421.33 2.6a27.6 27.6 0 0 0-27.44 27.73l2.94 382.66A27.62 27.62 0 0 0 703 811.05l409.38 7.47a27.62 27.62 0 0 0 28.11-27.22l9-392.72a27.62 27.62 0 0 0-27.8-28Z"
      />
      <rect
        className="BasicFunc_02_svg__cls-12"
        x={763.41}
        y={397.81}
        width={341.16}
        height={19.42}
        rx={8.26}
      />
      <path
        className="BasicFunc_02_svg__cls-12"
        d="M1068.34 442.61a8.26 8.26 0 0 1-8.09 7.81l-283.92 5.17a8.25 8.25 0 0 1-8.41-8.25v-.68a8.27 8.27 0 0 1 8.13-8.26l283.95-4.5a8.27 8.27 0 0 1 8.34 8.71ZM1097.32 795h-.26l-384-4.61a21.61 21.61 0 0 1-21.35-22l9.88-270.19a21.62 21.62 0 0 1 22-21.16l383.41 7.4a21.64 21.64 0 0 1 21.19 22l-9.21 267.29a21.66 21.66 0 0 1-21.66 21.27ZM723.18 481a17.61 17.61 0 0 0-17.6 17.28l-9.88 270.16a17.62 17.62 0 0 0 17.4 17.91l384 4.61a17.67 17.67 0 0 0 17.82-17.3l9.21-267.31a17.63 17.63 0 0 0-17.27-17.91L723.54 481Zm403 25.43Z"
      />
      <path
        d="M1121.17 533.1H703a2 2 0 0 1 0-4h418.21a2 2 0 0 1 0 4Z"
        style={{
          fill: "#fff",
        }}
      />
      <path
        className="BasicFunc_02_svg__cls-6"
        d="m577.56 499.75 1374.37-23.22c55.34-.94 99.75 46.52 96.46 103.07l-47.3 813.53c-2.59 44.52-38.41 79.4-82 79.83L598 1486c-32.63.32-59.59-26-60.83-59.37l-31.6-849.04c-1.57-42.02 30.92-77.14 71.99-77.84Z"
      />
      <path
        className="BasicFunc_02_svg__cls-6"
        d="m623.66 547.16 1274.57-21a88.16 88.16 0 0 1 89.46 93.38l-43.88 737a76.9 76.9 0 0 1-76 72.33l-1225.19 11.81a55.9 55.9 0 0 1-56.41-53.78l-29.32-769.23a67.93 67.93 0 0 1 66.77-70.51Z"
      />
      <path
        className="BasicFunc_02_svg__cls-12"
        d="M1940.22 497.74 596.11 519.93a71.65 71.65 0 0 0-70.41 74.36l2.17 57h1503.41l3.28-55a93 93 0 0 0-94.34-98.55Z"
      />
      <g className="BasicFunc_02_svg__cls-14">
        <path
          className="BasicFunc_02_svg__cls-7"
          d="M638.38 547.45a12.09 12.09 0 1 1-12.09-12.08 12.09 12.09 0 0 1 12.09 12.08ZM638.38 582.88a12.09 12.09 0 1 1-12.09-12.09 12.09 12.09 0 0 1 12.09 12.09ZM638.38 618.3a12.09 12.09 0 1 1-12.09-12.09 12.09 12.09 0 0 1 12.09 12.09Z"
        />
      </g>
      <path
        className="BasicFunc_02_svg__cls-15"
        d="M822.22 994h-145a34.66 34.66 0 0 0-34.62 36.36l16.5 336.92a34.65 34.65 0 0 0 34.61 33h128.51a34.66 34.66 0 0 0 34.66-34.66v-337.01A34.66 34.66 0 0 0 822.22 994ZM1170.92 688.24l-508.79 7.86A34.67 34.67 0 0 0 628 732.45l8.48 173a34.65 34.65 0 0 0 34.61 33h500.32a34.66 34.66 0 0 0 34.66-34.66V722.9a34.66 34.66 0 0 0-35.15-34.66Z"
      />
      <rect
        className="BasicFunc_02_svg__cls-16"
        x={899.86}
        y={993.95}
        width={306.25}
        height={406.24}
        rx={34.66}
      />
      <circle
        className="BasicFunc_02_svg__cls-16"
        cx={1377.79}
        cy={782.63}
        r={87.03}
      />
      <circle
        className="BasicFunc_02_svg__cls-16"
        cx={1594.09}
        cy={782.63}
        r={87.03}
      />
      <circle
        className="BasicFunc_02_svg__cls-16"
        cx={1810.39}
        cy={785.48}
        r={87.03}
        transform="rotate(-6.84 1811.597 785.945)"
      />
      <path
        className="BasicFunc_02_svg__cls-6"
        d="M1429.5 764.24a55.9 55.9 0 1 1-51.71-34.66c.94 0 1.88 0 2.82.07v-14.17q-1.41-.06-2.82-.06a70 70 0 1 0 64.71 43.17ZM1622.93 832.78a55.9 55.9 0 1 1-29.79-103.2c.94 0 1.88 0 2.82.07v-14.17q-1.41-.06-2.82-.06A70.06 70.06 0 1 0 1630 845.1ZM1857 816.91a55.9 55.9 0 1 1-46.23-87.33c.94 0 1.88 0 2.82.07v-14.17q-1.41-.06-2.82-.06a70.06 70.06 0 1 0 57.7 109.8Z"
      />
      <path
        className="BasicFunc_02_svg__cls-15"
        d="M1448.7 906.53h-141.82a16.12 16.12 0 0 0 0 32.24h141.82a16.12 16.12 0 0 0 0-32.24ZM1665 906.53h-141.82a16.12 16.12 0 0 0 0 32.24H1665a16.12 16.12 0 0 0 0-32.24ZM1739.47 938.77h141.83a16.12 16.12 0 1 0 0-32.24h-141.83a16.12 16.12 0 0 0 0 32.24Z"
      />
      <rect
        className="BasicFunc_02_svg__cls-15"
        x={1290.76}
        y={956.68}
        width={174.06}
        height={127.16}
        rx={16.12}
      />
      <rect
        className="BasicFunc_02_svg__cls-15"
        x={1507.06}
        y={956.68}
        width={174.06}
        height={127.16}
        rx={16.12}
      />
      <rect
        className="BasicFunc_02_svg__cls-15"
        x={1723.36}
        y={956.68}
        width={174.06}
        height={127.16}
        rx={16.12}
      />
      <path
        className="BasicFunc_02_svg__cls-12"
        d="M927.47 1210.84v127.91a36.21 36.21 0 0 0 36.21 36.25h175.18a37.19 37.19 0 0 0 37.19-37.19v-192.63c0-16.21-20-23.85-30.81-11.77l-33.85 37.83a37.32 37.32 0 0 1-50.58 4.7l-3.24-2.49a32 32 0 0 0-40.18 1l-18.54 15.77a49 49 0 0 1-51.38 7.56 14.27 14.27 0 0 0-20 13.06Z"
      />
      <path
        className="BasicFunc_02_svg__cls-16"
        d="M1299.37 1267.3h29.36v132.89h-29.36zM1348.43 1312.98h29.36v87.22h-29.36zM1397.49 1200.1h29.36v200.09h-29.36zM1446.55 1352.12h29.36v48.07h-29.36zM1495.61 1226.85h29.36v173.34h-29.36zM1544.66 1256.86h29.36v143.33h-29.36zM1593.72 1289.49h29.36v110.71h-29.36zM1642.78 1159.65h29.36v240.55h-29.36zM1691.84 1185.09h29.36v215.1h-29.36zM1740.9 1223.59h29.36v176.6h-29.36zM1789.96 1356.58h29.36v43.61h-29.36zM1839.02 1271.87h29.36v128.32h-29.36z"
      />
      <path
        className="BasicFunc_02_svg__cls-15"
        d="M1376.2 1159.65H1247a10.28 10.28 0 1 0 0 20.55h129.2a10.28 10.28 0 0 0 0-20.55ZM1376.2 1127.49H1247a10.28 10.28 0 1 0 0 20.55h129.2a10.28 10.28 0 0 0 0-20.55ZM1609.26 1159.65h-129.19a10.28 10.28 0 1 0 0 20.55h129.19a10.28 10.28 0 0 0 0-20.55ZM1609.26 1127.49h-129.19a10.28 10.28 0 1 0 0 20.55h129.19a10.28 10.28 0 0 0 0-20.55Z"
      />
      <path
        d="m289.47 766.55 636.87-10.76c25.64-.44 46.22 21.55 44.7 47.76l-21.92 377c-1.2 20.63-17.8 36.79-38 37L299 1223.58c-15.12.15-27.62-12-28.19-27.51l-14.7-393.45c-.73-19.48 14.33-35.75 33.36-36.07Z"
        style={{
          fill: "url(#BasicFunc_02_svg__linear-gradient-7)",
        }}
      />
      <path
        className="BasicFunc_02_svg__cls-6"
        d="m299.23 776.15 620.25-10.24A42.89 42.89 0 0 1 963 811.35L941.66 1170a37.41 37.41 0 0 1-37 35.19l-596.2 5.81a27.2 27.2 0 0 1-27.46-26.2l-14.26-374.33a33.07 33.07 0 0 1 32.49-34.32Z"
      />
      <path
        className="BasicFunc_02_svg__cls-12"
        d="M920.92 765.62 298.07 775.9a33.19 33.19 0 0 0-32.63 34.46l1 26.38h696.67l1.52-25.49a43.07 43.07 0 0 0-43.71-45.63Z"
      />
      <g className="BasicFunc_02_svg__cls-14">
        <path
          className="BasicFunc_02_svg__cls-7"
          d="M317.65 788.65a5.6 5.6 0 1 1-5.6-5.6 5.59 5.59 0 0 1 5.6 5.6ZM317.65 805.07a5.6 5.6 0 1 1-5.6-5.6 5.6 5.6 0 0 1 5.6 5.6ZM317.65 821.48a5.6 5.6 0 1 1-5.6-5.6 5.59 5.59 0 0 1 5.6 5.6Z"
        />
      </g>
      <circle
        className="BasicFunc_02_svg__cls-12"
        cx={416.73}
        cy={977.18}
        r={88.78}
        transform="rotate(-76.72 416.693 977.164)"
      />
      <path
        d="M464.32 1009.25a57 57 0 1 1-47.16-89.1c1 0 1.92 0 2.88.08v-14.46q-1.44-.06-2.88-.06a71.48 71.48 0 1 0 58.87 112Z"
        style={{
          fill: "url(#BasicFunc_02_svg__linear-gradient-8)",
        }}
      />
      <circle
        className="BasicFunc_02_svg__cls-15"
        cx={560.34}
        cy={909.58}
        r={12.58}
        transform="rotate(-79.35 560.287 909.559)"
      />
      <path
        d="M548.75 908.88a11.48 11.48 0 0 1 .17-1.45Z"
        style={{
          fill: "url(#BasicFunc_02_svg__linear-gradient-9)",
        }}
      />
      <path
        className="BasicFunc_02_svg__cls-15"
        d="M572.91 988.52a12.58 12.58 0 1 1-12.49-12.66 12.58 12.58 0 0 1 12.49 12.66Z"
      />
      <path
        d="M548.75 987.75a11.64 11.64 0 0 1 .17-1.46Z"
        style={{
          fill: "url(#BasicFunc_02_svg__linear-gradient-10)",
        }}
      />
      <circle
        className="BasicFunc_02_svg__cls-15"
        cx={560.34}
        cy={1067.3}
        r={12.58}
        transform="rotate(-79.35 560.283 1067.282)"
      />
      <path
        d="M548.75 1066.61a11.87 11.87 0 0 1 .17-1.46Z"
        style={{
          fill: "url(#BasicFunc_02_svg__linear-gradient-11)",
        }}
      />
      <path
        className="BasicFunc_02_svg__cls-12"
        d="m593.36 909 .06 2.22c.16 6 3.91 10.76 8.48 10.78l253.13.53c4.73 0 8.57-5 8.56-11.2 0-6.07-3.71-11-8.35-11.15l-253.19-2.75c-4.89-.15-8.87 5.14-8.69 11.57ZM593.36 987.46l.06 2.22c.16 6 3.91 10.76 8.48 10.78l253.1.54c4.73 0 8.57-5 8.56-11.2 0-6.07-3.71-11-8.35-11.15l-253.19-2.75c-4.86-.14-8.84 5.15-8.66 11.56ZM593.36 1065.94l.06 2.22c.16 6 3.91 10.76 8.48 10.78l253.13.53c4.73 0 8.57-5 8.56-11.2 0-6.08-3.71-11-8.35-11.15l-253.19-2.75c-4.89-.13-8.87 5.16-8.69 11.57ZM879.13 1127.8l-543.4 9.65a15.68 15.68 0 0 0-15.22 18.17l2.39 15a15.7 15.7 0 0 0 15.56 13.22l538.62-2.11a15.7 15.7 0 0 0 15.54-14l2.4-22.51a15.71 15.71 0 0 0-15.89-17.42ZM1362.92 1021.12l3.25 193.25a27.51 27.51 0 0 0 27.5 27.05H1773a27.51 27.51 0 0 0 27.51-27.51V1033a27.51 27.51 0 0 0-26.62-27.5l-382.55-12.38a27.52 27.52 0 0 0-28.42 28Z"
      />
      <path
        className="BasicFunc_02_svg__cls-7"
        d="m1394.32 1040 8.23 177.71a12.62 12.62 0 0 0 12.83 12l345.4-6.08a12.63 12.63 0 0 0 12.4-12.62v-154.14a12.63 12.63 0 0 0-12-12.61l-353.63-17.51a12.63 12.63 0 0 0-13.23 13.25Z"
      />
      <path
        className="BasicFunc_02_svg__cls-12"
        d="M1421.86 1056.61v12.81a8.07 8.07 0 0 0 8.21 8.07l297.91-4.86a8.06 8.06 0 0 0 7.94-8.07 8.08 8.08 0 0 0-7.86-8.08l-297.91-7.94a8.08 8.08 0 0 0-8.29 8.07ZM1426.31 1105v11.74a8.08 8.08 0 0 0 8.36 8.08l283.74-9.89a8.07 8.07 0 0 0 7.79-8.07v-3.66a8.07 8.07 0 0 0-8.13-8.08l-283.73 1.8a8.08 8.08 0 0 0-8.03 8.08Z"
      />
    </g>
  </svg>
);

export default SvgBasicFunc02;
