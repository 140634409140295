import * as React from "react";

const SvgDsiabledProvides = (props) => (
  <svg
    id="DsiabledProvides_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300.29 270.24"
    {...props}
  >
    <defs>
      <style>{".DsiabledProvides_svg__cls-1{fill:#2d4c6b}"}</style>
    </defs>
    <path
      className="DsiabledProvides_svg__cls-1"
      d="M210.89 87h12.81a13.52 13.52 0 0 1 13.51 13.51v47.18h8.5v-47.16a22 22 0 0 0-22-22h-12.82V51.2A25 25 0 0 0 186 26.27H48.41A25 25 0 0 0 23.48 51.2v65.33a25 25 0 0 0 24.93 24.94H186a25 25 0 0 0 24.94-24.94Zm-8.51 29.51A16.45 16.45 0 0 1 186 133H48.41A16.45 16.45 0 0 1 32 116.53V51.2a16.45 16.45 0 0 1 16.41-16.43H186a16.45 16.45 0 0 1 16.38 16.43ZM119.41 206.98h77.13v8.5h-77.13zM108.84 183.08l-44.16-4.54a11.44 11.44 0 0 0-12.61 11.39v3.78H25.41a4.26 4.26 0 1 0 0 8.51h26.66v23.5H25.41a4.25 4.25 0 1 0 0 8.5h26.8a11.42 11.42 0 0 0 12.47 9.69l44.16-4.53A11.41 11.41 0 0 0 119.11 228v-33.54a11.41 11.41 0 0 0-10.27-11.38Zm1.77 44.92a2.93 2.93 0 0 1-2.64 2.92l-44.16 4.53a2.94 2.94 0 0 1-3.24-2.92v-42.6a2.93 2.93 0 0 1 2.92-2.93h.32l44.19 4.54a2.93 2.93 0 0 1 2.64 2.92Z"
    />
    <circle
      className="DsiabledProvides_svg__cls-1"
      cx={69.64}
      cy={66.55}
      r={9.54}
    />
    <circle
      className="DsiabledProvides_svg__cls-1"
      cx={69.64}
      cy={101.19}
      r={9.54}
    />
    <circle
      className="DsiabledProvides_svg__cls-1"
      cx={117.18}
      cy={66.55}
      r={9.54}
    />
    <circle
      className="DsiabledProvides_svg__cls-1"
      cx={117.18}
      cy={101.19}
      r={9.54}
    />
    <circle
      className="DsiabledProvides_svg__cls-1"
      cx={164.73}
      cy={66.55}
      r={9.54}
      transform="matrix(.99 -.16 .16 .99 -8.53 27.25)"
    />
    <circle
      className="DsiabledProvides_svg__cls-1"
      cx={164.73}
      cy={101.19}
      r={9.54}
      transform="rotate(-10.9 164.756 101.179)"
    />
    <path
      className="DsiabledProvides_svg__cls-1"
      d="M241.46 153.46a37.68 37.68 0 1 0 37.68 37.68 37.68 37.68 0 0 0-37.68-37.68Zm18.43 62.19-43.45-42.23 6.59-6.79 43.45 42.24Z"
    />
  </svg>
);

export default SvgDsiabledProvides;
