import * as React from "react";

const SvgLogo = (props) => (
  <svg
    id="Logo_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 266.91 67.57"
    {...props}
  >
    <defs>
      <linearGradient
        id="Logo_svg__linear-gradient"
        x1={5.19}
        y1={46.03}
        x2={5.19}
        y2={67.24}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.7} stopColor="#21aad4" />
        <stop offset={0.72} stopColor="#21aad4" />
        <stop offset={0.78} stopColor="#20a5d1" />
        <stop offset={0.85} stopColor="#1b98c9" />
        <stop offset={0.93} stopColor="#1482bb" />
        <stop offset={1} stopColor="#0b66a9" />
      </linearGradient>
      <linearGradient
        id="Logo_svg__linear-gradient-2"
        x1={5.33}
        y1={12.85}
        x2={5.33}
        y2={59.86}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.68} stopColor="#21aad4" />
        <stop offset={1} stopColor="#034895" />
      </linearGradient>
      <linearGradient
        id="Logo_svg__linear-gradient-3"
        x1={5.19}
        y1={33.14}
        x2={5.19}
        y2={12.85}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#034895" />
        <stop offset={0.66} stopColor="#20a7d2" />
        <stop offset={1} stopColor="#21aad4" />
      </linearGradient>
      <linearGradient
        id="Logo_svg__linear-gradient-4"
        x1={30.71}
        y1={38.41}
        x2={61.24}
        y2={38.41}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.65} stopColor="#21aad4" />
        <stop offset={1} stopColor="#034895" />
      </linearGradient>
      <linearGradient
        id="Logo_svg__linear-gradient-5"
        x1={24.75}
        y1={27.64}
        x2={67.79}
        y2={27.64}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#08579f" />
        <stop offset={0.39} stopColor="#07579e" />
        <stop offset={1} stopColor="#20a0cf" />
      </linearGradient>
      <linearGradient
        id="Logo_svg__linear-gradient-6"
        x1={32.19}
        y1={59.32}
        x2={32.19}
        y2={12.46}
        xlinkHref="#Logo_svg__linear-gradient-3"
      />
      <linearGradient
        id="Logo_svg__linear-gradient-7"
        x1={20.71}
        y1={60.15}
        x2={60.34}
        y2={60.15}
        xlinkHref="#Logo_svg__linear-gradient-4"
      />
      <linearGradient
        id="Logo_svg__linear-gradient-8"
        x1={59.89}
        y1={67.57}
        x2={59.89}
        y2={46.51}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.47} stopColor="#21aad4" />
        <stop offset={1} stopColor="#034895" />
      </linearGradient>
      <radialGradient
        id="Logo_svg__radial-gradient"
        cx={5.33}
        cy={5.33}
        r={5.33}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.25} stopColor="#21bed4" />
        <stop offset={0.64} stopColor="#1a94c7" />
        <stop offset={1} stopColor="#1788c0" />
      </radialGradient>
      <style>{".Logo_svg__cls-1{fill:#21aad4}"}</style>
    </defs>
    <path
      className="Logo_svg__cls-1"
      d="M98.86 54.55v-2.9h3.44v2.9ZM99 67.32V55.81h3.26v11.51Z"
    />
    <path d="m118.61 67.32-1.91-4.21h-8.87l-1.91 4.21h-1.16l7-15.13h1.07l7 15.13Zm-6.33-13.91-4 8.69h7.92ZM134.66 67.32 124.08 54v13.32H123v-15h1l10.36 13.07v-13.1h1.08v15ZM153.86 62.76a7.86 7.86 0 0 1-1.54 2.47 7.15 7.15 0 0 1-2.39 1.71 7.5 7.5 0 0 1-3.1.63 7.3 7.3 0 0 1-5.47-2.32 7.61 7.61 0 0 1-1.53-2.46 8.07 8.07 0 0 1-.53-2.94v-.05a8.16 8.16 0 0 1 .54-2.95 7.86 7.86 0 0 1 1.54-2.47 7.21 7.21 0 0 1 2.38-1.71 7.94 7.94 0 0 1 6.2 0 7.35 7.35 0 0 1 2.38 1.69 7.61 7.61 0 0 1 1.53 2.46 8.07 8.07 0 0 1 .53 2.94 8.18 8.18 0 0 1-.54 3Zm-.61-3a7 7 0 0 0-.49-2.62 6.55 6.55 0 0 0-1.33-2.14 6.22 6.22 0 0 0-2-1.45 6.07 6.07 0 0 0-2.57-.54 6.22 6.22 0 0 0-2.57.53 6.07 6.07 0 0 0-2 1.44 6.47 6.47 0 0 0-1.32 2.13 7.13 7.13 0 0 0-.48 2.62 7.15 7.15 0 0 0 .49 2.63 6.55 6.55 0 0 0 1.33 2.15 6.22 6.22 0 0 0 2 1.45 6.07 6.07 0 0 0 2.57.54 6.22 6.22 0 0 0 2.57-.53 6.07 6.07 0 0 0 2-1.44 6.47 6.47 0 0 0 1.32-2.13 7.13 7.13 0 0 0 .48-2.62ZM167.79 65.09a3.86 3.86 0 0 1-1 1.3 4.81 4.81 0 0 1-1.57.84 6.42 6.42 0 0 1-2 .3 8.44 8.44 0 0 1-3.25-.6 10.07 10.07 0 0 1-2.8-1.8l.73-.84a11.51 11.51 0 0 0 1.23 1 6.45 6.45 0 0 0 1.26.68 6.81 6.81 0 0 0 1.35.41 7.51 7.51 0 0 0 1.55.14 5 5 0 0 0 1.54-.22 3.51 3.51 0 0 0 1.2-.63 2.93 2.93 0 0 0 .78-.94 2.64 2.64 0 0 0 .28-1.19 2.85 2.85 0 0 0-.19-1.08 2.1 2.1 0 0 0-.66-.86 4.89 4.89 0 0 0-1.3-.69 12.78 12.78 0 0 0-2.1-.57 15 15 0 0 1-2.32-.66 5.73 5.73 0 0 1-1.58-.88 3 3 0 0 1-.89-1.17 3.82 3.82 0 0 1-.28-1.52 3.32 3.32 0 0 1 .37-1.56 3.79 3.79 0 0 1 1-1.25 4.56 4.56 0 0 1 1.52-.84 6 6 0 0 1 1.93-.3 7.94 7.94 0 0 1 2.82.45 8.51 8.51 0 0 1 2.33 1.37l-.71.88a7 7 0 0 0-2.15-1.31 7.12 7.12 0 0 0-2.33-.38 4.85 4.85 0 0 0-1.52.22 3.81 3.81 0 0 0-1.15.61 2.57 2.57 0 0 0-.74.91 2.28 2.28 0 0 0-.26 1.09 3.16 3.16 0 0 0 .18 1.09 2.1 2.1 0 0 0 .67.88 4.85 4.85 0 0 0 1.34.72 16.4 16.4 0 0 0 2.19.6 8.54 8.54 0 0 1 3.74 1.47 3.24 3.24 0 0 1 1.16 2.63 3.68 3.68 0 0 1-.37 1.7Z" />
    <path
      className="Logo_svg__cls-1"
      d="M188.22 62.29v.41h-8a2.73 2.73 0 0 0 1 1.69A2.9 2.9 0 0 0 183 65a3.73 3.73 0 0 0 1.46-.28 5.2 5.2 0 0 0 1.33-.9l1.87 1.65a5.61 5.61 0 0 1-2 1.57 6.09 6.09 0 0 1-2.72.57 6.43 6.43 0 0 1-2.39-.43 5.35 5.35 0 0 1-1.91-1.22 5.55 5.55 0 0 1-1.25-1.96 6.2 6.2 0 0 1-.45-2.41 6.41 6.41 0 0 1 .42-2.32 5.88 5.88 0 0 1 1.17-1.91 5.36 5.36 0 0 1 1.79-1.29 5.58 5.58 0 0 1 2.31-.47 5.65 5.65 0 0 1 2.49.51 5.21 5.21 0 0 1 1.75 1.39 6 6 0 0 1 1 2 8.39 8.39 0 0 1 .33 2.35c.03.15.02.33.02.44Zm-3.94-3.4a2.15 2.15 0 0 0-1.65-.67 2.22 2.22 0 0 0-1.67.66 3.39 3.39 0 0 0-.82 1.78h4.91a3.2 3.2 0 0 0-.77-1.77Z"
    />
    <path d="M203.08 67.32 192.5 54v13.32h-1.07v-15h1l10.36 13.07v-13.1h1.08v15ZM208.38 67.32v-15h10.69v1h-9.57v6h8.6v1h-8.6v6.05h9.68v1ZM233.58 67.32l-4.79-6.23h-4.91v6.23h-1.12v-15h6.31a7.56 7.56 0 0 1 2.21.31 5.31 5.31 0 0 1 1.69.88 4.07 4.07 0 0 1 1.08 1.36 3.78 3.78 0 0 1 .39 1.72 4.06 4.06 0 0 1-.34 1.68 3.81 3.81 0 0 1-.92 1.28 4.68 4.68 0 0 1-1.39.87 7.37 7.37 0 0 1-1.75.46l5 6.42Zm-.26-10.72a2.92 2.92 0 0 0-1.13-2.42 5.1 5.1 0 0 0-3.19-.88h-5.13v6.78h5.06a6.5 6.5 0 0 0 1.75-.23 4.4 4.4 0 0 0 1.39-.68 3.39 3.39 0 0 0 .91-1.08 3.1 3.1 0 0 0 .33-1.45ZM248.53 66.9a8.16 8.16 0 0 1-3.36.67A7.89 7.89 0 0 1 242 67a6.89 6.89 0 0 1-2.37-1.66 7.13 7.13 0 0 1-1.48-2.46 8.47 8.47 0 0 1-.52-3v-.08a8.25 8.25 0 0 1 .53-2.92 7.52 7.52 0 0 1 1.5-2.48 7.26 7.26 0 0 1 2.34-1.72 7.09 7.09 0 0 1 3-.64 10.88 10.88 0 0 1 1.68.12 7.32 7.32 0 0 1 2.66 1 10.23 10.23 0 0 1 1.15.84l-.73.84a8 8 0 0 0-2-1.29A6.91 6.91 0 0 0 245 53a5.8 5.8 0 0 0-2.51.54 6 6 0 0 0-2 1.46 6.59 6.59 0 0 0-1.28 2.14 7.35 7.35 0 0 0-.45 2.57 8 8 0 0 0 .44 2.7 6 6 0 0 0 1.28 2.15 5.87 5.87 0 0 0 2 1.41 6.76 6.76 0 0 0 2.69.52 7.12 7.12 0 0 0 1.39-.14 8.23 8.23 0 0 0 1.3-.38 8.1 8.1 0 0 0 1.15-.54 7.19 7.19 0 0 0 .95-.66v-4.21h-5v-1h6.1v5.78a10.26 10.26 0 0 1-2.53 1.56ZM260.47 61.35v6h-1.12v-5.98l-6.44-9.08h1.4l5.62 8.07 5.65-8.07h1.33Z" />
    <path
      d="M10.39 59.86A9.64 9.64 0 0 1 1 67.24H0V46c0 6.87 4.46 12.57 10.39 13.86Z"
      style={{
        fill: "url(#Logo_svg__linear-gradient)",
      }}
    />
    <path
      d="M10.66 12.85v44.74a9.41 9.41 0 0 1-.27 2.27C4.46 58.57 0 52.87 0 46V33.14c5.93-1.54 10.39-8.3 10.39-16.41a20.51 20.51 0 0 0-.39-3.88Z"
      style={{
        fill: "url(#Logo_svg__linear-gradient-2)",
      }}
    />
    <circle
      cx={5.33}
      cy={5.33}
      r={5.33}
      style={{
        fill: "url(#Logo_svg__radial-gradient)",
      }}
    />
    <path
      d="M10.39 16.73c0 8.11-4.46 14.87-10.39 16.41V22.5a9.65 9.65 0 0 1 9.65-9.65H10a20.51 20.51 0 0 1 .39 3.88Z"
      style={{
        fill: "url(#Logo_svg__linear-gradient-3)",
      }}
    />
    <path
      d="M39.25 34.15h22v8.53H30.71a8.53 8.53 0 0 1 8.54-8.53Z"
      style={{
        fill: "url(#Logo_svg__linear-gradient-4)",
      }}
    />
    <path
      d="M46.27 12.59A21.5 21.5 0 0 0 24.75 34.1a.91.91 0 0 0 0 .17 18.86 18.86 0 0 0 .1 2s0 0 0 .07a13.64 13.64 0 0 0 1.34 5.18 3 3 0 0 1-.09-1.27s0 0 0-.06c-.05-.52-.08-1-.08-1.59v-.13a17.16 17.16 0 0 1 33.47-5.3 7.22 7.22 0 0 1-6.24 9.49h-.55 11.07a4 4 0 0 0 4-4V34.1a21.51 21.51 0 0 0-21.5-21.51Z"
      style={{
        fill: "url(#Logo_svg__linear-gradient-5)",
      }}
    />
    <path
      d="m44.93 13.44 2.49-.85-2.63-.11H43.6a26.63 26.63 0 0 0-22 41.61l.11.11a24.8 24.8 0 0 0 11.46 4.88 25.74 25.74 0 0 0 3.63.26h7.77v-.49.08c-9.19 0-17-7.44-18.15-16.54v-.09a18.71 18.71 0 0 1-.17-2.3 2.44 2.44 0 0 1 0-.27v-.25a27.53 27.53 0 0 1 18.68-26.04Z"
      style={{
        fill: "url(#Logo_svg__linear-gradient-6)",
      }}
    />
    <path
      d="M60.34 58.83v.32A26.13 26.13 0 0 1 22 53.79a24.11 24.11 0 0 0 14.79 5Z"
      style={{
        fill: "#f1f2f2",
      }}
    />
    <path
      d="M60.34 58.83v8.74H43a24.19 24.19 0 0 1-22.29-14.85c.41.37.84.73 1.28 1.07a24.11 24.11 0 0 0 14.79 5Z"
      style={{
        fill: "url(#Logo_svg__linear-gradient-7)",
      }}
    />
    <path
      d="M60 55.14v3.66a8.76 8.76 0 0 1-8.77 8.77h9.37a8 8 0 0 0 8-8V46.51a8.63 8.63 0 0 0-8.6 8.63Z"
      style={{
        fill: "url(#Logo_svg__linear-gradient-8)",
      }}
    />
    <path
      style={{
        fill: "none",
        stroke: "#21aad4",
        strokeMiterlimit: 10,
        strokeWidth: "1.98px",
      }}
      d="M83.71.17v67.24"
    />
  </svg>
);

export default SvgLogo;
