import "./../css/Privacy.css";
import { appName } from "../data/staticData";

const TermsOfUse = () => {
  return (
    <div className={"privacy__overlay"}>
      <div>
        <p>
          Μέσω του διαδικτυακού τόπου μας ({appName}.gr) σας δίνουμε τη
          δυνατότητα να ενημερώνεστε για τη δράση της DOTSOFT AE καθώς και για
          τις υπηρεσίες και τα προϊόντα που παρέχει η DOTSOFT. Πριν
          χρησιμοποιήσετε τον διαδικτυακό τόπο μας διαβάστε προσεκτικά τους
          όρους που διέπουν τη χρήση του.
        </p>

        <h1>ΟΡΙΣΜΟΙ</h1>

        <ul>
          <li>
            Ως Διαδικτυακός Τόπος ορίζεται ο διαδικτυακός τόπος site.{appName}
            .gr.
          </li>
          <li>
            Με τη χρήση α’ πληθυντικού προσώπου και του όρου «DOTSOFT» εννοείται
            η DOTSOFT Ολοκληρωμενες Εφαρμογες Διαδικτυου και Βασεων Δεδομενων
            ΑE, Κουντουριώτου 3, 546 25, Θεσσαλονίκη, Αρ.ΓΕΜΗ 059277904000, ΑΦΜ
            999566850, ΔΟΥ ΦΑΕ Θεσσαλονίκης.
          </li>
          <li>
            Με τη χρήση β’ πληθυντικού προσώπου εννοούνται οι επισκέπτες του
            Διαδικτυακού Τόπου.
          </li>
        </ul>

        <p>Ως όροι εννοούνται οι όροι χρήσης που ακολουθούν:</p>

        <h1>Η ΙΣΧΥΣ ΤΩΝ ΟΡΩΝ</h1>
        <p>Οι Όροι ρυθμίζουν τη χρήση του Διαδικτυακού Τόπου μας από εσάς.</p>

        <p>
          Εφόσον χρησιμοποιείτε τον Διαδικτυακό Τόπο συνεπάγεται ότι αποδέχεστε
          τους Όρους πλήρως και ανεπιφύλακτα. Εάν διαφωνείτε και δεν επιθυμείτε
          να δεσμεύεστε από τους Όρους, οφείλετε να μη χρησιμοποιείτε τον
          Διαδικτυακό Τόπο.
        </p>

        <p>
          Εάν η χρήση οποιασδήποτε υπηρεσίας, συναλλαγής, εφαρμογής του
          Διαδικτυακού Τόπου ρυθμίζεται από ειδικότερους όρους χρήσης, οι όροι
          αυτοί θα ισχύουν από κοινού με τους Όρους. Σε περίπτωση σύγκρουσης,
          υπερισχύουν οι ειδικότεροι όροι χρήσης κάθε υπηρεσίας, συναλλαγής,
          εφαρμογής.
        </p>

        <h1>ΤΡΟΠΟΠΟΙΗΣΗ ΤΩΝ ΟΡΩΝ</h1>

        <p>
          Έχουμε δικαίωμα να τροποποιούμε τους Όρους, όποτε το κρίνουμε
          αναγκαίο. Κάθε τροποποίηση ξεκινάει να ισχύει μόλις αναρτηθεί στον
          Διαδικτυακό Τόπο.
        </p>

        <p>
          Οφείλετε να ελέγχετε για ενδεχόμενες τροποποιήσεις των Όρων. Εφόσον
          εξακολουθείτε να χρησιμοποιείτε τον Διαδικτυακό Τόπο μετά την ανάρτηση
          των νέων Όρων, συνεπάγεται ότι αποδέχεστε τις τροποποιήσεις.
        </p>

        <h1>ΟΙ ΥΠΟΧΡΕΩΣΕΙΣ ΣΑΣ</h1>

        <p>
          Ως επισκέπτης έχετε την υποχρέωση να χρησιμοποιείτε τον Διαδικτυακό
          Τόπο για νόμιμους σκοπούς σύμφωνα με τους κανόνες του ελληνικού,
          ευρωπαϊκού και διεθνούς δικαίου, τη νομοθεσία που διέπει τις
          τηλεπικοινωνίες, σύμφωνα με τις οδηγίες που αναρτούμε στον Διαδικτυακό
          Τόπο, ενεργώντας με καλή πίστη και σύμφωνα με τα χρηστά ήθη.
        </p>

        <p>
          Χρησιμοποιείτε τον Διαδικτυακό Τόπο με δική σας πρωτοβουλία, με δικό
          σας εξοπλισμό και λογισμικό, και είστε οι ίδιοι υπεύθυνοι για την
          προστασία του εξοπλισμού σας από ιούς, εξωτερικές απειλές/επιθέσεις,
          μεταξύ άλλων και της προστασίας από λοιπό κακόβουλο λογισμικό.
        </p>

        <p>
          Έχετε την υποχρέωση να απέχετε από οποιαδήποτε ενέργεια που περιορίζει
          ή εμποδίζει τρίτους να χρησιμοποιούν τον Διαδικτυακό Τόπο, ή/και έχει
          ως σκοπό τη φόρτωση ή την υπονόμευση, ή την αλλοίωση, ή/και την πτώση,
          βλάβη, δυσλειτουργία του. Επίσης, έχετε την υποχρέωση να απέχετε από
          οποιαδήποτε εν γένει παράνομη, καταχρηστική, αθέμιτη ενέργεια,
          χρησιμοποιώντας τον Διαδικτυακό Τόπο.
        </p>

        <p>
          Ευθύνεστε για οποιαδήποτε ζημία υποστούμε που οφείλεται σε πράξεις ή
          παραλείψεις σας που συνιστούν παράβαση των υποχρεώσεών σας με βάση
          τους Όρους, περιλαμβανομένων εννοείται και περιπτώσεων εμπλοκής μας σε
          αντιδικίες, περιπτώσεων στις οποίες θα υποχρεωθούμε να καταβάλουμε
          πρόστιμα ή να καταβάλουμε αποζημιώσεις σε τρίτους εξαιτίας της
          παράβασης των υποχρεώσεών σας ως επισκέπτη, με δικαίωμά μας να
          κινηθούμε αναγωγικά εναντίον σας.
        </p>

        <h1>ΠΕΡΙΟΡΙΣΜΟΣ ΕΥΘΥΝΗΣ ΜΑΣ</h1>

        <p>
          Ο Διαδικτυακός Τόπος σάς παρέχεται ως έχει. Χρησιμοποιείτε τον
          Διαδικτυακό Τόπο αποκλειστικά με δική σας ευθύνη. Δεν εγγυόμαστε και
          συνεπώς δεν ευθυνόμαστε για τη λειτουργικότητα και το περιεχόμενό του.
        </p>

        <p>
          Παρόλο που καταβάλλουμε κάθε δυνατή προσπάθεια, δεν μπορούμε να
          εγγυηθούμε την ορθότητα, την πληρότητα, την ακεραιότητα, το χρονικά
          επίκαιρο, την τεχνική αρτιότητα, την ακρίβεια, τη σαφήνεια, την
          καταλληλότητα, την εγκυρότητα, τη διαθεσιμότητα, την αδιάκοπη μετάδοση
          των πληροφοριών και του περιεχομένου του Διαδικτυακού Τόπου και τη
          χρήση ή την πρόσβαση σε αυτόν.
        </p>

        <p>
          Σε καμία περίπτωση, συμπεριλαμβανομένης και αυτής της αμέλειας, δεν
          προκύπτει δική μας ευθύνη για οποιαδήποτε ζημία τυχόν προκληθεί σε
          εσάς εξαιτίας της χρήσης του Διαδικτυακού Τόπου και των πληροφοριών
          που περιέχονται σε αυτόν.
        </p>

        <p>
          Έχουμε λάβει όλα τα απαραίτητα μέτρα για την υψηλού επιπέδου ασφάλεια
          και προστασία αποκλειστικά και μόνο του δικού μας Διαδικτυακού Τόπου,
          όπως αυτά επιβάλλονται από την διεθνή πρακτική.
        </p>

        <p>
          Αναβαθμίζουμε τα συστήματα προστασίας μας και διαθέτουμε αντιιικά
          προγράμματα. Ωστόσο, δεν εγγυόμαστε την ασφαλή διασύνδεσή σας με τον
          Διαδικτυακό μας Τόπο και την απουσία ιών και δεν φέρουμε ευθύνη σε
          περίπτωση ζημίας του εξοπλισμού, του λογισμικού, των αρχείων ή άλλης
          ζημίας σας, η οποία προκαλείται από ιό ή άλλο κακόβουλο λογισμικό.
        </p>

        <h1>ΔΙΚΑΙΩΜΑΤΑ ΒΙΟΜΗΧΑΝΙΚΗΣ ΚΑΙ ΠΝΕΥΜΑΤΙΚΗΣ ΙΔΙΟΚΤΗΣΙΑΣ</h1>

        <p>
          Η επωνυμία, το εμπορικό σήμα και τα λοιπά διακριτικά γνωρίσματα όσο
          και το σύνολο του περιεχομένου του Διαδικτυακού Τόπου (εξαιρούνται τα
          προστατευόμενα δικαιώματα τρίτων) το οποίο περιλαμβάνει ενδεικτικά και
          όχι αποκλειστικά:
        </p>

        <p>
          κείμενα, γραφικά, φωτογραφίες, βίντεο, ειδήσεις, άρθρα, πληροφορίες,
          δεδομένα, σχεδιαγράμματα, απεικονίσεις, ονομασίες και περιγραφές
          προϊόντων-υπηρεσιών, τη διεπαφή, την παρουσίαση και τον τρόπο
          διάρθρωσης του υλικού και το λογισμικό
        </p>

        <p>
          αποτελούν αντικείμενα αποκλειστικών δικαιωμάτων πνευματικής και
          βιομηχανικής ιδιοκτησίας μας και προστατεύονται σύμφωνα με τις
          σχετικές διατάξεις του ελληνικού, ευρωπαϊκού και διεθνούς δικαίου
          διανοητικής (πνευματικής και βιομηχανικής) ιδιοκτησίας.
        </p>

        <p>
          αποτελούν αντικείμενα αποκλειστικών δικαιωμάτων πνευματικής και
          βιομηχανικής ιδιοκτησίας μας και προστατεύονται σύμφωνα με τις
          σχετικές διατάξεις του ελληνικού, ευρωπαϊκού και διεθνούς δικαίου
          διανοητικής (πνευματικής και βιομηχανικής) ιδιοκτησίας.
        </p>

        <p>
          Ρητά διευκρινίζεται ότι σε καμία περίπτωση δεν μπορεί να θεωρηθεί ότι
          με τα ανωτέρω σας παραχωρούμε με οποιονδήποτε τρόπο δικαιώματα
          πνευματικής ιδιοκτησίας.
        </p>

        <p>
          Κατά τα λοιπά απαγορεύεται κάθε πράξη ή ενέργεια που γίνεται χωρίς την
          προηγούμενη έγγραφη συναίνεσή μας και αφορά ενδεικτικά την με
          οποιονδήποτε τρόπο, μέσο και μορφή:
        </p>

        <p>
          αντιγραφή, αναπαραγωγή, διαγραφή, αναδημοσίευση, πώληση, μετάδοση,
          διανομή, εκτέλεση, έκδοση, φόρτωση, μετάφραση, τροποποίηση, καθώς και
          εκμετάλλευση του κάθε μορφής υλικού, τμηματικά ή άλλως, που
          εμφανίζεται και περιέχεται στον Διαδικτυακό Τόπο.
        </p>

        <p>
          Όλα τα λοιπά εμπορικά σήματα, διακριτικά γνωρίσματα και προϊόντα
          πνευματικής ιδιοκτησίας τρίτων που ενδέχεται να εμφανίζονται στον
          Διαδικτυακό Τόπο προστατεύονται με ευθύνη των νόμιμων δικαιούχων τους.
        </p>

        <h1>ΣΥΝΔΕΣΗ ΜΕ ΔΙΑΔΙΚΤΥΑΚΟΥΣ ΤΟΠΟΥΣ ΤΡΙΤΩΝ</h1>

        <p>
          Ο Διαδικτυακός Τόπος ενδέχεται να περιέχει υπερσυνδέσμους (link,
          hyperlink, banner) προς διαδικτυακούς τόπους που ανήκουν σε τρίτους
          και επομένως δεν τους διαχειριζόμαστε εμείς, αλλά ούτε μπορούμε να
          παρέμβουμε στο περιεχόμενό τους. Γι’ αυτόν τον λόγο δεν αναλαμβάνουμε
          καμία ευθύνη για αυτούς τους διαδικτυακούς τόπους και το περιεχόμενό
          τους, και δεν σας παρέχουμε κανενός είδους εγγύηση, παρότρυνση ή
          επιδοκιμασία σχετικά με αυτούς.
        </p>

        <p>
          Οι υπερσύνδεσμοι υπάρχουν αποκλειστικά για να σας διευκολύνουν. Οι
          διαδικτυακοί τόποι στους οποίους παραπέμπουν διέπονται από αυτοτελείς
          και ξεχωριστούς όρους χρήσης. Για τυχόν ερωτήσεις ή άλλα ζητήματα που
          σχετίζονται με τη χρήση αυτών των διαδικτυακών τόπων, απευθυνθείτε
          στους διαχειριστές τους.
        </p>

        <h1>ΕΦΑΡΜΟΣΤΕΟ ΔΙΚΑΙΟ</h1>

        <p>
          Οι Όροι διέπονται από το ελληνικό δίκαιο. Αρμόδια δικαστήρια για την
          ερμηνεία των Όρων και για την επίλυση τυχόν διαφορών σχετικά με αυτούς
          ορίζονται τα Δικαστήρια της Θεσσαλονίκης.
        </p>

        <p>
          Σε περίπτωση που κάποιος από τους Όρους κριθεί άκυρος ή ακυρωθεί, το
          γεγονός αυτό δεν θα επηρεάζει τους υπόλοιπους Όρους, οι οποίοι θα
          παραμένουν σε ισχύ και θα εφαρμόζονται κανονικά.
        </p>

        <h1>ΓΛΩΣΣΑ</h1>

        <p>
          Οι Όροι έχουν καταρτιστεί στην ελληνική γλώσσα και έχουν εν συνεχεία
          μεταφραστεί και στα Αγγλικά. Η πρωτότυπη και αυθεντική εκδοχή των όρων
          είναι στα Ελληνικά. Σε περίπτωση που προκύπτουν οποιεσδήποτε διαφορές
          μεταξύ της ελληνικής και αγγλικής εκδοχής των Όρων, η εκδοχή των Όρων
          στα Ελληνικά επικρατεί.
        </p>
      </div>
    </div>
  );
};

export default TermsOfUse;
