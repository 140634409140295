import "./../css/Recommended.css";
import RecommendedCard from "./RecommendedCard";
import { imgContent } from "../data/staticData";

const line5 = [
  {
    img: "https://www.smartiscity.gr/wp-content/uploads/2021/11/dimos-%CE%BA%CE%BF.jpg",
    title: "Δήμος Κω",
  },
  {
    img: "https://www.smartiscity.gr/wp-content/uploads/2021/11/dimos-%CE%BA%CE%BF.jpg",
    title: "Δήμος Κω",
  },
  {
    img: "https://www.smartiscity.gr/wp-content/uploads/2021/11/dimos-%CE%BA%CE%BF.jpg",
    title: "Δήμος Κω",
  },
  {
    img: "https://www.smartiscity.gr/wp-content/uploads/2021/11/dimos-%CE%BA%CE%BF.jpg",
    title: "Δήμος Κω",
  },
  {
    img: "https://www.smartiscity.gr/wp-content/uploads/2021/11/dimos-%CE%BA%CE%BF.jpg",
    title: "Δήμος Κω",
  },
];
const Recommended = ({ hoverEffect }) => {
  return (
    <div className={"recommended__wrapper"}>
      <div className={"recommended__lines_4or5 recommended__lines__free"}>
        {imgContent.slice(0, 3).map((item, index) => (
          <RecommendedCard
            key={index}
            SvgIcon={item.svgIcon}
            title={item.title}
            content={item.content}
            hoverEffect={hoverEffect}
          />
        ))}
      </div>
      <div className={"recommended__lines_4or5 recommended__lines__free"}>
        {imgContent.slice(3, 5).map((item, index) => (
          <RecommendedCard
            key={index}
            SvgIcon={item.svgIcon}
            title={item.title}
            content={item.content}
            hoverEffect={hoverEffect}
          />
        ))}
      </div>

      <div className={"recommended__lines_4or5 recommended__lines__free"}>
        {imgContent.slice(5, 8).map((item, index) => (
          <RecommendedCard
            key={index}
            SvgIcon={item.svgIcon}
            title={item.title}
            content={item.content}
            hoverEffect={hoverEffect}
          />
        ))}
      </div>

      <div className={"recommended__lines_4or5 recommended__lines__free"}>
        {imgContent.slice(8, 13).map((item, index) => (
          <RecommendedCard
            key={index}
            SvgIcon={item.svgIcon}
            title={item.title}
            content={item.content}
            hoverEffect={hoverEffect}
          />
        ))}
      </div>
    </div>
  );
};

export default Recommended;
