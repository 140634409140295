import "../css/description.css";
import { useTranslation } from "react-i18next";
import {
  appNameTitle,
  Section_1_svgIcon,
  Section_2_svgIcon,
  Section_3_svgIcon,
  setction_1_array,
  setction_1_arrayTitle,
  setction_1_title,
  setction_2_array,
  setction_2_arrayTitle,
  setction_2_title,
  setction_3_array,
  setction_3_arrayTitle,
  setction_3_title,
  setction_4_array,
  setction_4_arrayTitle,
  setction_4_title,
  setction_5_arrayTitle,
  setction_5_title,
} from "../data/staticData";

const Description = ({ title }) => {
  const { t } = useTranslation();
  return (
    <div className={"left-right-flex  functionality-content"}>
      <h2 style={{ marginTop: "40px" }}>
        {t(title)} {appNameTitle}
      </h2>
      <div className="functionality-description-1">
        <div className="functionality-description-1__column-1">
          {Section_1_svgIcon && <Section_1_svgIcon />}
        </div>
        <div className="functionality-description-1__column-2">
          {setction_1_title && <h2>{t(setction_1_title)}</h2>}
          <ul className={"bulletsStyle"}>
            {t(setction_1_arrayTitle)}
            {setction_1_array.map((item, index) => (
              <li key={index}>
                {t(item)}
                <div>
                  <div />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="functionality-description-2">
        <div className="functionality-description-2__column-1">
          {setction_2_title && <h2>{t(setction_2_title)}</h2>}
          <ul className={"bulletsStyle"}>
            {t(setction_2_arrayTitle)}
            {setction_2_array.map((item, index) => (
              <li key={index}>
                {t(item)}
                <div>
                  <div />
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="functionality-description-2__column-2">
          {Section_2_svgIcon && <Section_2_svgIcon />}
        </div>
      </div>
      <div
        className="functionality-description-3"
        id="functionality-description-3"
      >
        <div className="functionality-description-3__column-1">
          {Section_3_svgIcon && <Section_3_svgIcon />}
        </div>
        <div className="functionality-description-3__column-2">
          {setction_3_title && <h2>{t(setction_3_title)}</h2>}
          <ul className={"bulletsStyle"}>
            {t(setction_3_arrayTitle)}
            {setction_3_array.map((item, index) => (
              <li key={index}>
                {t(item)}
                <div>
                  <div />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Description;
