import "./../css/RecommendedCard.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const RecommendedCard = ({ img, title, content, hoverEffect, SvgIcon }) => {
  return (
    <div
      className={
        hoverEffect ? "recommended__card hoverEffect" : "recommended__card"
      }
    >
      {img && (
        <LazyLoadImage
          src={img}
          alt="content img"
          width="100%"
          height="100%"
          effect="opacity"
        />
      )}
      {SvgIcon && <SvgIcon />}
      {/*<img src={img} alt={"img "} />*/}
      <div className={"recommended__card__content"}>
        <h4>{title}</h4>
        {content && (
          <div className={"extra_content"}>
            <p>{content}</p>
          </div>
        )}
      </div>
    </div>
  );
};
export default RecommendedCard;
