import * as React from "react";

const SvgSumLocation = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 3710 3710"
    {...props}
  >
    <defs>
      <linearGradient
        id="sumLocation_svg__linear-gradient"
        x1={1965.62}
        y1={1545.18}
        x2={1882.77}
        y2={1626.22}
        gradientTransform="rotate(16.84 1910.454 1600.155)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#2d4c6b" />
        <stop offset={1} stopColor="#21aad4" />
      </linearGradient>
      <linearGradient
        id="sumLocation_svg__linear-gradient-2"
        x1={2287.94}
        y1={1369.06}
        x2={2205.09}
        y2={1450.11}
        gradientTransform="rotate(73.15 2232.363 1423.678)"
        xlinkHref="#sumLocation_svg__linear-gradient"
      />
      <linearGradient
        id="sumLocation_svg__linear-gradient-3"
        x1={2590.34}
        y1={1495.33}
        x2={2507.49}
        y2={1576.38}
        gradientTransform="rotate(58.28 2534.745 1549.884)"
        xlinkHref="#sumLocation_svg__linear-gradient"
      />
      <linearGradient
        id="sumLocation_svg__linear-gradient-4"
        x1={2842.88}
        y1={1246.11}
        x2={2760.03}
        y2={1327.16}
        xlinkHref="#sumLocation_svg__linear-gradient"
      />
      <linearGradient
        id="sumLocation_svg__linear-gradient-5"
        x1={3155.24}
        y1={1066.67}
        x2={3072.39}
        y2={1147.72}
        gradientTransform="rotate(37.98 3099.718 1121.213)"
        xlinkHref="#sumLocation_svg__linear-gradient"
      />
      <style>
        {
          ".sumLocation_svg__cls-2{fill:#2d4c6b}.sumLocation_svg__cls-6{fill:#21aad4}.sumLocation_svg__cls-7{opacity:.61}.sumLocation_svg__cls-16,.sumLocation_svg__cls-8{fill:#fff}.sumLocation_svg__cls-9{opacity:.74}.sumLocation_svg__cls-16{opacity:.77}"
        }
      </style>
    </defs>
    <g id="sumLocation_svg__Illustration">
      <path
        d="M2906.47 2806.82c-71.64 73.84-135.73 117.24-184.81 150C2243 3276.41 1694.24 3238.48 1525.09 3207c-272.91-50.8-501.61-140.81-699.54-319.64-449.77-406.34-427.89-1254-84.22-1779.1 40.75-62.26 383.91-570.92 932.21-642.59 13.41-1.75 23.41-2.75 32.18-3.68 84.82-9 610.44-56.38 1039 302.69 61.35 51.4 170.53 137.87 251.64 285.51 290.29 528.42 335.86 1317.81-89.89 1756.63Z"
        style={{
          fill: "#e7eaef",
        }}
      />
      <path
        className="sumLocation_svg__cls-2"
        d="M2882.25 1579.84c-.82-3.53-1.64-7.06-2.5-10.59-129.59-533.5-671.86-859.78-1211.2-728.77L1905.76 1817Z"
      />
      <path
        className="sumLocation_svg__cls-2"
        d="m1902.86 1813.25 950.62 326.38c59.06-171.49 72.44-361.07 28.94-550.88-1.37-6-2.81-11.92-4.28-17.85Z"
      />
      <path
        d="m1905.75 1817 193.19 986.2q9.29-1.81 18.56-3.8c356.77-76.9 628.12-334.41 738.88-656Z"
        style={{
          fill: "#5a84a8",
        }}
      />
      <path
        d="m1782.22 1735.63-200.63-972.19c-453.63 93.61-772.17 479.34-800 920.35l995.09 58.21a5.34 5.34 0 0 0 5.54-6.37Z"
        style={{
          fill: "#ff9c01",
        }}
      />
      <path
        d="m1906.36 1812.84-1002.75-33.33c-4.29 87.85 3.61 161.48 23.61 251.48 93.34 419.9 436.8 719.47 838.82 777Z"
        style={{
          fill: "#4dc0db",
        }}
      />
      <path
        className="sumLocation_svg__cls-6"
        d="M1757.6 2811a1001.65 1001.65 0 0 0 342.66-7.5l-192.15-986.39Z"
      />
      <g className="sumLocation_svg__cls-7">
        <path
          className="sumLocation_svg__cls-8"
          d="M1547.46 2718.77a6.59 6.59 0 0 1-2.55-.51 981.23 981.23 0 0 1-362.77-260.07c-101.82-116.59-175.08-258.27-211.87-409.72a934.33 934.33 0 0 1-9-40.95 6.67 6.67 0 0 1 13.09-2.6c2.66 13.41 5.66 27 8.91 40.4 36.28 149.37 108.54 289.11 209 404.09A967.9 967.9 0 0 0 1550 2705.93a6.67 6.67 0 0 1-2.55 12.84Z"
        />
      </g>
      <g className="sumLocation_svg__cls-7">
        <path
          className="sumLocation_svg__cls-8"
          d="M2273 2723.07a6.68 6.68 0 0 1-2.47-12.88c248.23-98.52 445.57-298.83 541.41-549.57a6.67 6.67 0 0 1 12.47 4.76c-97.17 254.22-297.26 457.32-549 557.21a6.44 6.44 0 0 1-2.41.48Z"
        />
      </g>
      <g className="sumLocation_svg__cls-7">
        <path
          className="sumLocation_svg__cls-8"
          d="M2686.85 1243.75a6.68 6.68 0 0 1-5.33-2.65c-119.93-158.85-283.75-275-473.75-336a6.67 6.67 0 0 1 4.08-12.71c192.63 61.83 358.72 179.64 480.32 340.7a6.68 6.68 0 0 1-5.32 10.69Z"
        />
      </g>
      <g className="sumLocation_svg__cls-9">
        <path
          className="sumLocation_svg__cls-8"
          d="M1327.83 1679.14h-.44l-510.55-32.94 1.16-7.2c13.43-86.56 35.54-170.38 69.56-263.77a6.67 6.67 0 1 1 12.53 4.57c-32.76 90-54.37 170.9-67.76 254l496 32a6.67 6.67 0 0 1-.42 13.33Z"
        />
      </g>
      <g className="sumLocation_svg__cls-9">
        <path
          className="sumLocation_svg__cls-8"
          d="M1728.29 1704.94h-.71l-104.15-6.73a6.67 6.67 0 1 1 .86-13.32l100.71 6.52-108.32-507.17a6.68 6.68 0 0 1 13.06-2.79l109 510.54a10.73 10.73 0 0 1-10.48 13Zm-2.57-10.19Z"
        />
      </g>
      <path
        className="sumLocation_svg__cls-6"
        d="m1912.36 1604.01-4.79-8.78 324.48-177.3 301.55 132.54 258.87-253.92.52-.31 304.06-179.44 5.09 8.62-303.53 179.13-262.84 257.81-303.24-133.29-320.17 174.94z"
      />
      <circle
        cx={1909.96}
        cy={1599.62}
        r={34.89}
        transform="rotate(-16.84 1910.59 1600.003)"
        style={{
          fill: "url(#sumLocation_svg__linear-gradient)",
        }}
      />
      <circle
        cx={2232.29}
        cy={1423.5}
        r={34.89}
        transform="rotate(-73.15 2232.484 1423.485)"
        style={{
          fill: "url(#sumLocation_svg__linear-gradient-2)",
        }}
      />
      <circle
        cx={2534.68}
        cy={1549.77}
        r={34.89}
        transform="rotate(-58.28 2534.82 1549.775)"
        style={{
          fill: "url(#sumLocation_svg__linear-gradient-3)",
        }}
      />
      <circle
        cx={2787.23}
        cy={1300.55}
        r={34.89}
        style={{
          fill: "url(#sumLocation_svg__linear-gradient-4)",
        }}
      />
      <circle
        cx={3099.59}
        cy={1121.11}
        r={34.89}
        transform="rotate(-37.98 3099.754 1121.115)"
        style={{
          fill: "url(#sumLocation_svg__linear-gradient-5)",
        }}
      />
      <path
        className="sumLocation_svg__cls-6"
        d="m1301.82 1128.32 129.13 8.79a6.49 6.49 0 0 0 5-11.2l-123.83-91.7Z"
      />
      <path
        className="sumLocation_svg__cls-6"
        d="m564.09 715 727.27-4.45c16.65-.1 30.18 12.24 30.05 27.41l-3.06 366.17c-.13 15.27-14 27.45-30.75 27l-719.39-19.71c-16-.43-28.75-12.27-29-26.84l-4.81-342c-.17-15.07 13.14-27.44 29.69-27.58Z"
      />
      <path
        d="m586.62 737.44 681-3.89c15.59-.09 28.26 10.69 28.14 24l-2.87 319.93c-.12 13.34 11.29 32.1-4.38 31.7l-698-25.34c-15-.38-26.91-10.72-27.11-23.46l-4.5-298.82c-.24-13.23 12.21-24.03 27.72-24.12Z"
        style={{
          opacity: 0.14,
          fill: "#fff",
        }}
      />
      <path
        className="sumLocation_svg__cls-16"
        d="m1288.52 1109.13-719.59-19.71a5.42 5.42 0 0 1-5.49-5.09l-4.81-342a4.84 4.84 0 0 1 1.62-3.67 5.8 5.8 0 0 1 4-1.56l727.26-4.45a5.9 5.9 0 0 1 4 1.52 4.82 4.82 0 0 1 1.65 3.7l-3 366.13a5.43 5.43 0 0 1-5.64 5.13Zm-719.29-25 719.38 19.7 2.82-366-727.14 4.56Z"
      />
      <path
        className="sumLocation_svg__cls-16"
        d="M606 820.19c0 13 7.55 23.46 16.85 23.46h618.58c9.3 0 16.85-10.5 16.85-23.46s-7.55-23.45-16.85-23.45H622.89c-9.3 0-16.89 10.5-16.89 23.45ZM639.92 883.65h579.94c12.62 0 22.85 6.3 22.85 14.07s-10.23 14.07-22.85 14.07H639.92c-12.62 0-22.84-6.3-22.84-14.07s10.22-14.07 22.84-14.07ZM617.08 961.8c0 7.76 10.21 14 22.8 14h580c12.6 0 22.81-6.28 22.81-14v-8.68c0-7.89-10.55-14.23-23.36-14l-580 8.68c-12.33.2-22.25 6.38-22.25 14ZM642.21 1006.08h579.94c12.62 0 22.85 6.3 22.85 14.07s-10.23 14.06-22.85 14.06H642.21c-12.62 0-22.85-6.29-22.85-14.06s10.23-14.07 22.85-14.07Z"
      />
    </g>
  </svg>
);

export default SvgSumLocation;
