import * as React from "react";

const SvgFastInformation = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 3710 3710"
    {...props}
  >
    <defs>
      <linearGradient
        id="fastInformation_svg__linear-gradient"
        x1={1741.3}
        y1={2843.81}
        x2={1709.82}
        y2={3083.6}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#444b8c" />
        <stop offset={1} stopColor="#26264f" />
      </linearGradient>
      <linearGradient
        id="fastInformation_svg__linear-gradient-2"
        x1={1267.15}
        y1={2018.12}
        x2={2124.12}
        y2={2018.12}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#2d4c6b" />
        <stop offset={1} stopColor="#21aad4" />
      </linearGradient>
      <linearGradient
        id="fastInformation_svg__linear-gradient-3"
        x1={1023.04}
        y1={1722.58}
        x2={1438.77}
        y2={1722.58}
        xlinkHref="#fastInformation_svg__linear-gradient-2"
      />
      <linearGradient
        id="fastInformation_svg__linear-gradient-4"
        x1={1277.84}
        y1={1246.44}
        x2={2986.9}
        y2={1246.44}
        xlinkHref="#fastInformation_svg__linear-gradient-2"
      />
      <linearGradient
        id="fastInformation_svg__linear-gradient-5"
        x1={2447.49}
        y1={1221.54}
        x2={3218.13}
        y2={1221.54}
        xlinkHref="#fastInformation_svg__linear-gradient-2"
      />
      <clipPath id="fastInformation_svg__clip-path">
        <path
          d="M3053 1909.48c40-12.82 74-42.2 100.94-87.35 27.42-45.94 46.38-105.77 56.33-177.82 20.93-151.46-.45-337.67-60.21-524.34s-150.48-350.68-255.47-461.83c-49.95-52.87-100.12-90.57-149.13-112-48.15-21.11-92.86-25.31-132.9-12.49s-74 42.2-100.94 87.34c-27.43 45.95-46.38 105.78-56.34 177.83-20.92 151.45.46 337.67 60.22 524.33s150.5 350.64 255.5 461.79c49.95 52.87 100.12 90.57 149.13 112 48.15 21.15 92.87 25.35 132.87 12.54Z"
          style={{
            fill: "none",
          }}
        />
      </clipPath>
      <style>
        {
          ".fastInformation_svg__cls-3{fill:#2d4c6b}.fastInformation_svg__cls-4{fill:#21aad4}.fastInformation_svg__cls-5{fill:#ff9c01}"
        }
      </style>
    </defs>
    <g id="fastInformation_svg__Illustration">
      <path
        d="M1724.55 3310.48c325.09-134 356.23-476.48 648.94-505.87 255-25.62 352 222.31 531 177.89 272.6-67.67 444-741.34 334.51-1295.27-113-572-588-1332.32-1375-1384.21-883.49-58.26-1552 816.6-1592.79 1584.34-4.42 83-30.48 778.69 482.62 1189.64 25.63 20.53 531.25 414.61 970.72 233.48Z"
        style={{
          fill: "#e7eaef",
        }}
      />
      <path
        className="fastInformation_svg__cls-3"
        d="M2510.51 2936.85c0 46.31-32.86 85.91-73.38 88.27l-1563 91.23c-46.42 2.71-84.32-35.6-84.32-85.41V788.88c0-49.81 37.9-88.8 84.32-86.92l1563 63.34c40.52 1.64 73.38 40.65 73.38 87Z"
      />
      <path
        className="fastInformation_svg__cls-3"
        d="M2556.4 2936.85c0 46.31-32.86 85.91-73.38 88.27l-1563 91.23c-46.41 2.71-84.31-35.6-84.31-85.41V788.88c0-49.81 37.9-88.8 84.31-86.92L2483 765.3c40.52 1.64 73.38 40.65 73.38 87Z"
      />
      <path
        className="fastInformation_svg__cls-4"
        d="M875.07 790.32v2238.47c0 26.62 20.17 47.06 45 45.65l1236.13-70 326.8-18.54c21.71-1.23 39.28-22.35 39.28-47.18V851c0-24.83-17.57-45.64-39.28-46.48l-338.6-13.14-1224.33-47.51c-24.83-.97-45 19.83-45 46.45Z"
      />
      <path
        className="fastInformation_svg__cls-5"
        d="M2500.64 2842.18a17.71 17.71 0 0 1-16.51 17.59l-1565.33 80c-10.4.53-18.87-7.07-18.87-17V790.37c0-9.89 8.47-17.61 18.87-17.22L2484.13 832a17.43 17.43 0 0 1 16.51 17.37Z"
      />
      <path
        d="m1885.24 2981-317.85 17.48c-17 .93-30.79-10.85-30.79-26.32V2971c0-15.48 13.8-28.74 30.79-29.64l317.85-16.71c16.52-.87 29.89 10.77 29.89 26v1.15c0 15.2-13.37 28.25-29.89 29.2Z"
        style={{
          fill: "url(#fastInformation_svg__linear-gradient)",
        }}
      />
      <path
        className="fastInformation_svg__cls-4"
        d="M1743.65 775.83c0 8.23-7.28 14.62-16.26 14.28s-16.29-7.3-16.29-15.55 7.29-14.63 16.29-14.28 16.26 7.32 16.26 15.55Z"
      />
      <g
        style={{
          opacity: 0.2,
        }}
      >
        <path
          d="M2142.7 2790.12c-6.37-136.62 145.53-216.74 130.94-409.47-1.32-17.39-14.89-166.53-134.93-267.62-107.79-90.78-198.39-56.63-397.89-129-139.82-50.74-297-107.8-360.59-222.26-126.25-227.32 234.52-454.37 160.68-748.65-21.16-84.33-80.9-187.69-238.69-295.63L920.06 702c-46.41-1.88-84.31 37.11-84.31 86.92v2242.02c0 49.81 37.9 88.12 84.31 85.41l1424.08-83.12c-151.68-87.69-198.14-171.93-201.44-243.11Z"
          style={{
            fill: "#aa6100",
          }}
        />
      </g>
      <path
        d="M1993.57 2472c-54.38 13.65-115.51-4-153.8-49.07l-540-635.52c-49.17-57.87-42-143.47 16.24-191.65 58.65-48.52 173.73-50.33 223.83 8l550.43 640.94c51.9 60.43 43.07 147.5-19.46 194-15.38 11.54-59.3 28.84-77.24 33.3Z"
        style={{
          fill: "url(#fastInformation_svg__linear-gradient-2)",
        }}
      />
      <path
        d="M1085.68 1509.12a61.22 61.22 0 0 1 8-1.95l188-62.47 157.07 490.64-183 55.84a60.7 60.7 0 0 1-13.71 6.39c-64.68 20.7-152.11-71.86-195.28-206.74s-25.76-261.01 38.92-281.71Z"
        style={{
          fill: "url(#fastInformation_svg__linear-gradient-3)",
        }}
      />
      <path
        d="M1281.7 1444.7c-19.17 186.26 32.94 349 157.07 490.64l1548.13 2-426.33-1381.77Z"
        style={{
          fill: "url(#fastInformation_svg__linear-gradient-4)",
        }}
      />
      <ellipse
        className="fastInformation_svg__cls-4"
        cx={2832.81}
        cy={1221.54}
        rx={358.51}
        ry={747.7}
        transform="rotate(-17.75 2832.896 1221.553)"
      />
      <path
        d="M3053 1909.48c40-12.82 74-42.2 100.94-87.35 27.42-45.94 46.38-105.77 56.33-177.82 20.93-151.46-.45-337.67-60.21-524.34s-150.48-350.68-255.47-461.83c-49.95-52.87-100.12-90.57-149.13-112-48.15-21.11-92.86-25.31-132.9-12.49s-74 42.2-100.94 87.34c-27.43 45.95-46.38 105.78-56.34 177.83-8.74 63.32-10.1 132.73-4.34 205.7 8 101.55 103.79 179.58 138.56 288.2 37 115.49 11.8 252.76 68.53 343.75 35 56.08 72.91 106.14 112.94 148.52 49.95 52.87 100.12 90.57 149.13 112 48.18 21.1 92.9 25.3 132.9 12.49Z"
        style={{
          fill: "url(#fastInformation_svg__linear-gradient-5)",
        }}
      />
      <path
        className="fastInformation_svg__cls-5"
        d="M2863.9 1221.89c57.75 180.41-15.85 361.9-162.31 404.75-144.19 42.2-305-66.26-361.11-241.65s12.33-355.29 154.92-402.46c144.84-47.92 310.75 58.94 368.5 239.36Z"
        style={{
          clipPath: "url(#fastInformation_svg__clip-path)",
        }}
      />
    </g>
  </svg>
);

export default SvgFastInformation;
