import React from "react";
import "../css/header.css";
import logo from "../img/gis_logo.png";
import MenuIcon from "@mui/icons-material/Menu";

import { animateScroll as scroll } from "react-scroll";

import { Link as Link1 } from "react-router-dom";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.updatePredicate = this.updatePredicate.bind(this);
    this.openSidebar = this.openSidebar.bind(this);
    this.state = {
      isMobile: false,
      isOpened: false,
    };
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate = () => {
    this.setState(() => {
      return {
        isMobile: window.innerWidth <= 1000,
      };
    });
  };

  openSidebar = () => {
    this.setState(() => {
      return {
        isOpened: !this.state.isOpened,
      };
    });
  };

  render() {
    return (
      <div className="header-container">
        {!this.state.isMobile ? (
          <div>
            <div className="header-container__navigation">
              <Link1
                style={{ color: "inherit", textDecoration: "inherit" }}
                to="/"
              >
                <img src={logo} alt=""></img>
              </Link1>
              <span>
                <Link1
                  style={{ color: "inherit", textDecoration: "inherit" }}
                  to="/"
                >
                  Βασικές Λειτουργίες
                </Link1>
              </span>
              <span>
                <Link1
                  style={{ color: "inherit", textDecoration: "inherit" }}
                  to="/"
                >
                  Πλεονεκτήματα
                </Link1>
              </span>
              <span
                onClick={() => {
                  scroll.scrollToBottom();
                }}
              >
                Επικοινωνία
              </span>
            </div>
            <div
              style={{
                width: 80 + "px",
                position: "absolute",
                right: 15 + "%",
                top: 30 + "%",
              }}
            >
              <a href="https://dotsoft.gr/" target="_blank" rel="noreferrer">
                <img
                  style={{ width: 100 + "%", height: 100 + "%" }}
                  src="https://www.smartiscity.gr/wp-content/uploads/2021/10/dotsoft_80x38.png"
                  alt={"img"}
                />
              </a>
            </div>
          </div>
        ) : (
          <div className="header-container__navigation-mobile">
            <img src={logo} alt="" />
            <MenuIcon className="menu" onClick={this.openSidebar} />
            <div
              className={this.state.isOpened ? "open-sidebar" : "close-sidebar"}
            >
              <h4>MENU</h4>
              <hr />
              <span>
                <Link1
                  style={{ color: "inherit", textDecoration: "inherit" }}
                  to="/"
                >
                  Βασικές Λειτουργίες
                </Link1>
              </span>
              <hr />
              <span>
                <Link1
                  style={{ color: "inherit", textDecoration: "inherit" }}
                  to="/"
                >
                  Πλεονεκτήματα
                </Link1>
              </span>
              <hr />
              <span onClick={() => {}}>Έπικοινωνία</span>
              <hr />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Header;
