import * as React from "react";

const SvgCosts = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 3710 3710"
    {...props}
  >
    <defs>
      <linearGradient
        id="costs_svg__linear-gradient"
        x1={3102.33}
        y1={1584.29}
        x2={2384.34}
        y2={1584.29}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#2d4c6b" />
        <stop offset={0.04} stopColor="#2b5d7e" />
        <stop offset={0.11} stopColor="#287599" />
        <stop offset={0.19} stopColor="#2589af" />
        <stop offset={0.28} stopColor="#2398c0" />
        <stop offset={0.39} stopColor="#22a2cb" />
        <stop offset={0.55} stopColor="#21a8d2" />
        <stop offset={1} stopColor="#21aad4" />
      </linearGradient>
      <linearGradient
        id="costs_svg__linear-gradient-2"
        x1={2715.6}
        y1={1510.78}
        x2={3044.18}
        y2={1510.78}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#dae3fe" />
        <stop offset={1} stopColor="#e9effd" />
      </linearGradient>
      <linearGradient
        id="costs_svg__linear-gradient-3"
        x1={2715.6}
        y1={1590.49}
        x2={3044.18}
        y2={1590.49}
        xlinkHref="#costs_svg__linear-gradient-2"
      />
      <linearGradient
        id="costs_svg__linear-gradient-4"
        x1={2715.6}
        y1={1666.95}
        x2={3044.18}
        y2={1666.95}
        xlinkHref="#costs_svg__linear-gradient-2"
      />
      <linearGradient
        id="costs_svg__linear-gradient-5"
        x1={2443.84}
        y1={1496.31}
        x2={2582.3}
        y2={1496.31}
        xlinkHref="#costs_svg__linear-gradient-2"
      />
      <linearGradient
        id="costs_svg__linear-gradient-6"
        x1={1963.52}
        y1={641.08}
        x2={1766.34}
        y2={2049.01}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#2d4c6b" />
        <stop offset={1} stopColor="#21aad4" />
      </linearGradient>
      <linearGradient
        id="costs_svg__linear-gradient-7"
        x1={1804.94}
        y1={1501.75}
        x2={2133.53}
        y2={1501.75}
        xlinkHref="#costs_svg__linear-gradient-2"
      />
      <linearGradient
        id="costs_svg__linear-gradient-8"
        x1={1804.94}
        y1={1581.46}
        x2={2133.53}
        y2={1581.46}
        xlinkHref="#costs_svg__linear-gradient-2"
      />
      <linearGradient
        id="costs_svg__linear-gradient-9"
        x1={1804.94}
        y1={1657.92}
        x2={2133.53}
        y2={1657.92}
        xlinkHref="#costs_svg__linear-gradient-2"
      />
      <linearGradient
        id="costs_svg__linear-gradient-10"
        x1={1533.19}
        y1={1487.28}
        x2={1671.65}
        y2={1487.28}
        xlinkHref="#costs_svg__linear-gradient-2"
      />
      <linearGradient
        id="costs_svg__linear-gradient-11"
        x1={1910.57}
        y1={1715.01}
        x2={1590.5}
        y2={3104.12}
        xlinkHref="#costs_svg__linear-gradient"
      />
      <linearGradient
        id="costs_svg__linear-gradient-12"
        x1={1807.03}
        y1={1970.46}
        x2={2135.61}
        y2={1970.46}
        xlinkHref="#costs_svg__linear-gradient-2"
      />
      <linearGradient
        id="costs_svg__linear-gradient-13"
        x1={1807.03}
        y1={2050.17}
        x2={2135.61}
        y2={2050.17}
        xlinkHref="#costs_svg__linear-gradient-2"
      />
      <linearGradient
        id="costs_svg__linear-gradient-14"
        x1={1807.03}
        y1={2126.63}
        x2={2135.61}
        y2={2126.63}
        xlinkHref="#costs_svg__linear-gradient-2"
      />
      <linearGradient
        id="costs_svg__linear-gradient-15"
        x1={1066.56}
        y1={512.74}
        x2={869.38}
        y2={1920.67}
        xlinkHref="#costs_svg__linear-gradient-6"
      />
      <linearGradient
        id="costs_svg__linear-gradient-16"
        x1={893.36}
        y1={1477.82}
        x2={1221.94}
        y2={1477.82}
        xlinkHref="#costs_svg__linear-gradient-2"
      />
      <linearGradient
        id="costs_svg__linear-gradient-17"
        x1={893.36}
        y1={1557.53}
        x2={1221.94}
        y2={1557.53}
        xlinkHref="#costs_svg__linear-gradient-2"
      />
      <linearGradient
        id="costs_svg__linear-gradient-18"
        x1={893.36}
        y1={1634}
        x2={1221.94}
        y2={1634}
        xlinkHref="#costs_svg__linear-gradient-2"
      />
      <linearGradient
        id="costs_svg__linear-gradient-19"
        x1={621.61}
        y1={1463.35}
        x2={760.07}
        y2={1463.35}
        xlinkHref="#costs_svg__linear-gradient-2"
      />
      <linearGradient
        id="costs_svg__linear-gradient-20"
        x1={2384.34}
        y1={2056.37}
        x2={3102.33}
        y2={2056.37}
        xlinkHref="#costs_svg__linear-gradient"
      />
      <linearGradient
        id="costs_svg__linear-gradient-21"
        x1={2715.6}
        y1={1982.85}
        x2={3044.18}
        y2={1982.85}
        xlinkHref="#costs_svg__linear-gradient-2"
      />
      <linearGradient
        id="costs_svg__linear-gradient-22"
        x1={2715.6}
        y1={2062.57}
        x2={3044.18}
        y2={2062.57}
        xlinkHref="#costs_svg__linear-gradient-2"
      />
      <linearGradient
        id="costs_svg__linear-gradient-23"
        x1={2715.6}
        y1={2139.03}
        x2={3044.18}
        y2={2139.03}
        xlinkHref="#costs_svg__linear-gradient-2"
      />
      <linearGradient
        id="costs_svg__linear-gradient-24"
        x1={2443.84}
        y1={1968.39}
        x2={2582.3}
        y2={1968.39}
        xlinkHref="#costs_svg__linear-gradient-2"
      />
      <style>
        {
          ".costs_svg__cls-3{fill:#2d4c6b}.costs_svg__cls-20,.costs_svg__cls-4{fill:#fff}.costs_svg__cls-4{opacity:.59}.costs_svg__cls-10{fill:#ff9c01}"
        }
      </style>
    </defs>
    <g id="costs_svg__Illustration">
      <path
        d="M2925.24 2858.46c-56.84 59.84-110.62 98.42-153.11 128.91-499.45 358.36-1090.44 253.9-1216 228.39-185.48-37.67-544-110.49-803.2-415.3C360.74 2339.3 421.81 1612.75 726 1133.15c55.63-87.73 349.54-574.64 946.89-652.72 361.5-47.26 782.91 48.05 1088.11 303.72 640.55 536.68 595 1620.85 164.24 2074.31Z"
        style={{
          fill: "#e7eaef",
        }}
      />
      <rect
        x={456}
        y={948.76}
        width={2798}
        height={2071.21}
        rx={30.51}
        style={{
          fill: "#21aad4",
        }}
      />
      <rect
        className="costs_svg__cls-3"
        x={517.82}
        y={1013.16}
        width={2650.62}
        height={1926.08}
        rx={102.87}
      />
      <rect
        className="costs_svg__cls-3"
        x={545.48}
        y={1061.89}
        width={2595.41}
        height={1851.72}
        rx={102.87}
      />
      <rect
        className="costs_svg__cls-4"
        x={1338.56}
        y={1114.57}
        width={43.42}
        height={1637.08}
        rx={6.44}
      />
      <rect
        className="costs_svg__cls-4"
        x={2258.91}
        y={1105.74}
        width={43.42}
        height={1637.08}
        rx={6.44}
      />
      <rect
        x={2384.34}
        y={1380.39}
        width={717.99}
        height={407.8}
        rx={20.78}
        style={{
          fill: "url(#costs_svg__linear-gradient)",
        }}
      />
      <rect
        className="costs_svg__cls-3"
        x={2399.84}
        y={1397.71}
        width={677.4}
        height={373.16}
        rx={70.04}
      />
      <rect
        x={2715.6}
        y={1492.18}
        width={328.58}
        height={37.2}
        rx={5.52}
        style={{
          fill: "url(#costs_svg__linear-gradient-2)",
        }}
      />
      <rect
        x={2715.6}
        y={1571.89}
        width={328.58}
        height={37.2}
        rx={5.52}
        style={{
          fill: "url(#costs_svg__linear-gradient-3)",
        }}
      />
      <rect
        x={2715.6}
        y={1648.35}
        width={328.58}
        height={37.2}
        rx={5.52}
        style={{
          fill: "url(#costs_svg__linear-gradient-4)",
        }}
      />
      <rect
        x={2443.84}
        y={1438.45}
        width={138.46}
        height={115.73}
        rx={7.33}
        style={{
          fill: "url(#costs_svg__linear-gradient-5)",
        }}
      />
      <path
        className="costs_svg__cls-10"
        d="M2472.16 1492.22a10.37 10.37 0 0 1 14.3 3.9l13.33 23.89a3.33 3.33 0 0 0 5.95-.26l33.06-73.6a3.69 3.69 0 0 1 7.06 1.59c-.34 22.52-6.32 65.31-42.29 101.13 0 0-19.82-7.7-35.79-42.76a10.89 10.89 0 0 1 4.38-13.89Z"
      />
      <rect
        x={1473.69}
        y={1371.36}
        width={717.99}
        height={407.8}
        rx={20.78}
        style={{
          fill: "url(#costs_svg__linear-gradient-6)",
        }}
      />
      <rect
        className="costs_svg__cls-3"
        x={1489.19}
        y={1388.68}
        width={677.4}
        height={373.16}
        rx={70.04}
      />
      <rect
        x={1804.94}
        y={1483.15}
        width={328.58}
        height={37.2}
        rx={5.52}
        style={{
          fill: "url(#costs_svg__linear-gradient-7)",
        }}
      />
      <rect
        x={1804.94}
        y={1562.86}
        width={328.58}
        height={37.2}
        rx={5.52}
        style={{
          fill: "url(#costs_svg__linear-gradient-8)",
        }}
      />
      <rect
        x={1804.94}
        y={1639.32}
        width={328.58}
        height={37.2}
        rx={5.52}
        style={{
          fill: "url(#costs_svg__linear-gradient-9)",
        }}
      />
      <rect
        x={1533.19}
        y={1429.42}
        width={138.46}
        height={115.73}
        rx={7.33}
        style={{
          fill: "url(#costs_svg__linear-gradient-10)",
        }}
      />
      <rect
        x={1475.78}
        y={1840.07}
        width={717.99}
        height={407.8}
        rx={20.78}
        style={{
          fill: "url(#costs_svg__linear-gradient-11)",
        }}
      />
      <rect
        className="costs_svg__cls-3"
        x={1491.28}
        y={1857.39}
        width={677.4}
        height={373.16}
        rx={70.04}
      />
      <rect
        x={1807.03}
        y={1951.86}
        width={328.58}
        height={37.2}
        rx={5.52}
        style={{
          fill: "url(#costs_svg__linear-gradient-12)",
        }}
      />
      <rect
        x={1807.03}
        y={2031.57}
        width={328.58}
        height={37.2}
        rx={5.52}
        style={{
          fill: "url(#costs_svg__linear-gradient-13)",
        }}
      />
      <rect
        x={1807.03}
        y={2108.03}
        width={328.58}
        height={37.2}
        rx={5.52}
        style={{
          fill: "url(#costs_svg__linear-gradient-14)",
        }}
      />
      <rect
        className="costs_svg__cls-20"
        x={1535.28}
        y={1898.13}
        width={138.46}
        height={115.73}
        rx={7.33}
      />
      <rect
        x={562.11}
        y={1347.43}
        width={717.99}
        height={407.8}
        rx={20.78}
        style={{
          fill: "url(#costs_svg__linear-gradient-15)",
        }}
      />
      <rect
        className="costs_svg__cls-3"
        x={577.6}
        y={1364.75}
        width={677.4}
        height={373.16}
        rx={70.04}
      />
      <rect
        x={893.36}
        y={1459.22}
        width={328.58}
        height={37.2}
        rx={5.52}
        style={{
          fill: "url(#costs_svg__linear-gradient-16)",
        }}
      />
      <rect
        x={893.36}
        y={1538.93}
        width={328.58}
        height={37.2}
        rx={5.52}
        style={{
          fill: "url(#costs_svg__linear-gradient-17)",
        }}
      />
      <rect
        x={893.36}
        y={1615.4}
        width={328.58}
        height={37.2}
        rx={5.52}
        style={{
          fill: "url(#costs_svg__linear-gradient-18)",
        }}
      />
      <rect
        x={621.61}
        y={1405.49}
        width={138.46}
        height={115.73}
        rx={7.33}
        style={{
          fill: "url(#costs_svg__linear-gradient-19)",
        }}
      />
      <rect
        x={2384.34}
        y={1852.47}
        width={717.99}
        height={407.8}
        rx={20.78}
        style={{
          fill: "url(#costs_svg__linear-gradient-20)",
        }}
      />
      <rect
        className="costs_svg__cls-3"
        x={2399.84}
        y={1869.78}
        width={677.4}
        height={373.16}
        rx={70.04}
      />
      <rect
        x={2715.6}
        y={1964.26}
        width={328.58}
        height={37.2}
        rx={5.52}
        style={{
          fill: "url(#costs_svg__linear-gradient-21)",
        }}
      />
      <rect
        x={2715.6}
        y={2043.97}
        width={328.58}
        height={37.2}
        rx={5.52}
        style={{
          fill: "url(#costs_svg__linear-gradient-22)",
        }}
      />
      <rect
        x={2715.6}
        y={2120.43}
        width={328.58}
        height={37.2}
        rx={5.52}
        style={{
          fill: "url(#costs_svg__linear-gradient-23)",
        }}
      />
      <rect
        x={2443.84}
        y={1910.52}
        width={138.46}
        height={115.73}
        rx={7.33}
        style={{
          fill: "url(#costs_svg__linear-gradient-24)",
        }}
      />
      <rect
        className="costs_svg__cls-4"
        x={562.11}
        y={1083.6}
        width={717.99}
        height={163.55}
        rx={13.32}
      />
      <rect
        className="costs_svg__cls-4"
        x={1464.82}
        y={1086.97}
        width={717.99}
        height={163.55}
        rx={13.32}
      />
      <rect
        className="costs_svg__cls-4"
        x={2372.8}
        y={1086.97}
        width={717.99}
        height={163.55}
        rx={13.32}
      />
      <rect
        className="costs_svg__cls-10"
        x={562.11}
        y={1840.07}
        width={717.99}
        height={407.8}
        rx={20.78}
      />
      <rect
        className="costs_svg__cls-10"
        x={577.6}
        y={1857.39}
        width={677.4}
        height={373.16}
        rx={20.78}
      />
      <rect
        className="costs_svg__cls-20"
        x={893.36}
        y={1951.86}
        width={328.58}
        height={37.2}
        rx={5.52}
      />
      <rect
        className="costs_svg__cls-20"
        x={893.36}
        y={2031.57}
        width={328.58}
        height={37.2}
        rx={5.52}
      />
      <rect
        className="costs_svg__cls-20"
        x={893.36}
        y={2108.03}
        width={328.58}
        height={37.2}
        rx={5.52}
      />
      <rect
        className="costs_svg__cls-20"
        x={621.61}
        y={1898.13}
        width={138.46}
        height={115.73}
        rx={7.33}
      />
    </g>
  </svg>
);

export default SvgCosts;
