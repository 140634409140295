import * as React from "react";

const SvgSearch = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3710 3710" {...props}>
    <defs>
      <style>{".search_svg__cls-3{fill:#2d4c6b}"}</style>
    </defs>
    <g id="search_svg__Illustration">
      <path
        d="M1589 579.11s-991.65 43.1-1047.91 915.23-316.49 1702 316.48 1751.48S2974.49 3391.57 3185.48 2872 3502 396.15 1589 579.11Z"
        style={{
          fill: "#e7eaef",
        }}
      />
      <circle
        cx={2315.2}
        cy={1617.27}
        r={697.72}
        style={{
          fill: "#fff",
          opacity: 0.8,
        }}
      />
      <path
        className="search_svg__cls-3"
        d="m658.28 3144.13-16.14-16.91c-25.73-27-23.68-69.4 4.6-94.7l1108.58-992.18 101.19 115.09L755.55 3147c-28.07 25.23-71.62 24-97.27-2.87Z"
      />
      <path
        className="search_svg__cls-3"
        d="M2911.16 1105.8c-279.62-344.17-775.44-399.74-1106.88-112.14-337.52 292.88-364.74 818.42-61.81 1161.39 295.82 334.91 789.42 360.64 1103.66 69.19 308.87-286.42 338.28-782.09 65.03-1118.44Zm-1072.29 962.7c-252.28-287.6-229.25-726.85 52.15-972.39 277.15-241.83 693.4-197.2 929.29 91.26 231.36 282.92 206.75 701.11-54.41 942.09-265.03 244.54-679.71 220.97-927.03-60.96Z"
      />
    </g>
  </svg>
);

export default SvgSearch;
