import React from "react";
import Functionality from "../components/functionality";
import "../css/wrapper.css";
import { useTranslation } from "react-i18next";
import { appNameTitle } from "../data/staticData";

const Wrapper = ({ title, gridList, boxSize }) => {
  const { t } = useTranslation();
  return (
    <div className="functionality-content" id="functionality">
      <h2>{title}</h2>
      <Functionality list={gridList} boxSize={boxSize} />
    </div>
  );
};
export default Wrapper;
