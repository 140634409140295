import * as React from "react";

const SvgMaps = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 3710 3710"
    {...props}
  >
    <defs>
      <linearGradient
        id="maps_svg__linear-gradient"
        x1={1123.58}
        y1={1188.91}
        x2={1473.08}
        y2={1188.91}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f36f56" />
        <stop offset={0.01} stopColor="#2d4c6b" />
        <stop offset={1} stopColor="#21aad4" />
      </linearGradient>
      <linearGradient
        id="maps_svg__linear-gradient-2"
        x1={2923.93}
        y1={2359.63}
        x2={3119.6}
        y2={2359.63}
        xlinkHref="#maps_svg__linear-gradient"
      />
      <linearGradient
        id="maps_svg__linear-gradient-3"
        x1={1300.03}
        y1={2470.76}
        x2={1645.59}
        y2={2470.76}
        xlinkHref="#maps_svg__linear-gradient"
      />
      <linearGradient
        id="maps_svg__linear-gradient-4"
        x1={1765.86}
        y1={1489.53}
        x2={1915.74}
        y2={1489.53}
        xlinkHref="#maps_svg__linear-gradient"
      />
      <clipPath id="maps_svg__clip-path">
        <path
          style={{
            fill: "none",
          }}
          d="m2304.47 867.8-823.38-85.2-823.37 96.34v2049.31l823.37-50.73 823.38 56.24 822.35-85.84V820.16l-822.35 47.64z"
        />
      </clipPath>
      <style>
        {
          ".maps_svg__cls-14,.maps_svg__cls-3{fill:#fff}.maps_svg__cls-4{fill:#2d4c6b}.maps_svg__cls-5{fill:#bdd0fb}.maps_svg__cls-8{fill:#91b3fa}.maps_svg__cls-13{fill:#ff9c01}.maps_svg__cls-14{opacity:.36}"
        }
      </style>
    </defs>
    <g id="maps_svg__Illustration">
      <path
        d="M2930.52 2856.57c-57 60-110.87 98.65-153.45 129.21-500.61 359.19-1093 254.48-1218.85 228.92-185.9-37.76-545.24-110.75-805-416.26-393-462.23-331.85-1190.44-27-1671.15 55.77-87.93 350.35-576 949.07-654.23 362.35-47.36 784.72 48.17 1090.6 304.43 642.04 537.92 596.34 1624.6 164.63 2079.08Z"
        style={{
          fill: "#e7eaef",
        }}
      />
      <path
        className="maps_svg__cls-3"
        d="m2330.39 810.99-866.59-89.8-866.59 101.55V2983l866.59-53.47 866.59 59.28 865.52-90.49V760.78l-865.52 50.21z"
      />
      <path
        className="maps_svg__cls-4"
        d="m1482.4 2880.28-823.37 50.73V881.7l823.37-96.34v2094.92zM2305.78 2936.52l-823.38-56.24V785.36l823.38 85.19v2065.97zM3128.13 2850.68l-822.35 85.84V870.55l822.35-47.63v2027.76z"
      />
      <path
        className="maps_svg__cls-5"
        d="M1463.76 785.36h18.64v2095.5h-18.64zM2298.98 2936.52l18.64-1.24V868.64h-18.64v2067.88z"
      />
      <g
        style={{
          clipPath: "url(#maps_svg__clip-path)",
        }}
      >
        <g
          style={{
            opacity: 0.69,
          }}
        >
          <path
            className="maps_svg__cls-8"
            d="m2544.17 891.24 213.47 163.33-145.5 136.42-171.49-159.53 103.52-140.22z"
          />
          <path
            style={{
              fill: "url(#maps_svg__linear-gradient)",
            }}
            d="m1123.58 1217.04 19.21-189.74 330.29 70.25v252.98l-349.5-133.49z"
          />
          <path
            style={{
              fill: "url(#maps_svg__linear-gradient-2)",
            }}
            d="m2923.93 2334.21 172.34-86.06 23.33 174.58-150.64 48.39-45.03-136.91z"
          />
          <path
            style={{
              fill: "url(#maps_svg__linear-gradient-3)",
            }}
            d="m1300.03 2473.14 173.05 89 172.52-28.51-15.59-117.96-156.93-36.3-173.05 93.77z"
          />
          <path
            className="maps_svg__cls-5"
            d="m1123.58 1217.04-469.57 165.33 10.03 38.6 67.82-23.88 434.51 137.4 7.26-31.14-391.74-123.87 336.17-118.36 313.62 240.81-43.15 347.51-342.99 28.35-301.62 473.54 27.83 24.02 292.13-458.64 354.94-29.34 50.3-405.01-345.54-265.32z"
          />
          <path
            className="maps_svg__cls-5"
            d="m977.02 2612.48-358.53-353.83 22.4-30.77 358.53 353.83-22.4 30.77zM2352.77 2259.87l-213.95-613.33-32.11 15.17 112.57 322.68-546.68 195.69 10.19 38.55 549.58-196.72 88.31 253.14 32.09-15.18zM2809.84 2140.54l-104.17 131.91-205.75-470.07-24.7 14.64 210.31 480.5.64-.38-80.23 101.59 25.51 27.29 203.89-258.18-25.5-27.3zM2684.55 1325.48l-20.11-32.84a12.94 12.94 0 0 0-1.44 1.36c11-12.54 160.28-222.84 258-361.14l12.18-17.26 222.14 261.18-24.62 28.38-195.19-229.42c-21.45 30.35-68.11 96.3-114.83 162.06-129.41 182.11-132.98 185.07-136.13 187.68ZM2236.95 1539.25l-69.6-277.38-85.08-252.84-380.8 26.35-2.05-40.26 405.93-28.09 95.03 282.67 69.83 278.24-33.26 11.31zM1142.36 1220.4l-27.64-2.46 14.43-219.62 346.74 83.43-5.61 31.6-315.9-76.01-12.02 183.06zM2607.79 1214.54l-17.29-25.21 144.21-134.09-199.82-167.44 16.19-26.19 229.48 192.29-172.77 160.64zM3167 2462.1l-229.86-948.65-33.34 10.95 173.48 715.96-162.27 81.04 10.92 29.65 155.93-77.87 18.68 139.84-121.41 39 7.38 31.1 138.79-44.58 8.36 34.51 33.34-10.95z"
          />
          <path
            className="maps_svg__cls-5"
            d="m1479 2839.5-509.78-236.1 12.78-37.48 484.58 224.43 138-224.58-124 34.48-211.2-114.4 14.61-36.56 200.28 108.47 159.4-44.32c15.35-4.27 28 12.49 19.64 26.06ZM2586.42 2406.49l-346.91 437.11-352.46-381.44-198.49-291.67-21.73 20.04 199.15 292.63.65.96 354.07 383.18-55.01 69.31 25.45 27.37 420.73-530.12-25.45-27.37z"
          />
        </g>
        <path
          className="maps_svg__cls-8"
          d="m1585.24 1522-121.07-155.88c-271.33-111.34-580.36-226.45-820.07-239.68l1.47-36.26c244.86 13.51 558.34 130.54 832.7 243.24l3.73 1.53 126.37 162.71ZM3108 2763.87c-264.3-188.88-563.26-396.79-771-477.19l-675.15 290.12-10.79-34.06 685.35-294.49 5.15 2c211.12 80.15 514.73 291.2 782.73 482.73ZM1789.63 1391.84l-207.28-597.36 28.91-13.6 207.28 597.36-28.91 13.6zM2729 1557.19c-53.62-242.14-156.91-408.89-315.79-509.8l14.94-31.87c87.65 55.68 160.34 131.62 216 225.72 50.59 85.47 88.21 185.86 115 306.89ZM1110.38 2552.46l-26.02-20.31 306.3-548.93 26.03 20.31-306.31 548.93z"
        />
        <path
          className="maps_svg__cls-8"
          d="m2814.97 1577.81-899.85-41.76 1.24-36.27 893.07 41.44 327.61-156.34 11.83 33.58-333.9 159.35zM664.64 2761.85l-11.22-33.87 817.51-367.09 160.62 18.57-3.09 36.12-153.23-17.72-810.59 363.99zM1718.18 2025c-351-4.33-679.95-25.09-847.72-228.89L893 1771c159.4 193.62 481.45 213.46 825.54 217.7Z"
        />
        <path
          className="maps_svg__cls-8"
          d="m1687.38 2883.86-73.37-508.79 1.12-4.15 211.33-784.32 94.72-186.67 374.85-235.84 250.05-320.33 23.08 24.41-253.63 324.91-371.91 233.99-88.49 174.39L1646 2377.63l72.13 500.22-30.75 6.01z"
        />
        <path
          className="maps_svg__cls-8"
          d="m3144.12 2844.88-437.99-922.64-809.7-345.31-144.12-40.02-523.06-46.03-577.56 492.69-18.43-29.27 586.85-500.62 5.75.5 531.27 46.75 148.31 41.18.85.36 821.5 350.35 2.81 5.93 440.81 928.57-27.29 17.56z"
        />
      </g>
      <ellipse
        className="maps_svg__cls-5"
        cx={1840.8}
        cy={1489.53}
        rx={104.59}
        ry={104.5}
      />
      <ellipse
        cx={1840.8}
        cy={1489.53}
        rx={74.94}
        ry={74.87}
        style={{
          fill: "url(#maps_svg__linear-gradient-4)",
        }}
      />
      <circle
        className="maps_svg__cls-13"
        cx={2375.55}
        cy={1767.34}
        r={65.07}
        transform="rotate(-76.72 2375.446 1767.342)"
      />
      <path
        className="maps_svg__cls-13"
        d="M2684.64 1048.85c0 123.77-186.41 439.52-283.52 595.26a41.21 41.21 0 0 1-70 0c-97.12-155.76-283.5-471.48-283.5-595.23 0-161.38 142.6-292.2 318.51-292.2s318.51 130.79 318.51 292.17Z"
      />
      <circle
        className="maps_svg__cls-14"
        cx={2373.91}
        cy={1062.29}
        r={194.39}
        transform="rotate(-45 2373.904 1062.287)"
      />
      <circle
        className="maps_svg__cls-14"
        cx={2355.07}
        cy={1043.18}
        r={141.34}
      />
      <path
        className="maps_svg__cls-3"
        d="M2366.13 1308.84c-130.28 0-236.27-106-236.27-236.28a4.24 4.24 0 0 1 8.47 0c0 125.62 102.19 227.81 227.8 227.81s227.81-102.19 227.81-227.81-102.19-227.81-227.81-227.81c-72.63 0-139.23 33.44-182.72 91.74a4.23 4.23 0 1 1-6.78-5.06c45.1-60.47 114.17-95.14 189.5-95.14 130.29 0 236.28 106 236.28 236.27s-105.99 236.28-236.28 236.28Z"
      />
    </g>
  </svg>
);

export default SvgMaps;
