import municipality from "./../icons/Boxes/Municipality";
import provides from "./../icons/Boxes/Provides";
import electricity from "./../icons/Boxes/Electricity";
import payments from "./../icons/Boxes/Payments";
import disabledProvides from "./../icons/Boxes/DsiabledProvides";

import SvgGeneralInformation from "../icons/functions/GeneralInformation";
import SvgFastInformation from "../icons/functions/FastInformation";
import SvgArchiveAccounts from "../icons/functions/ArchiveAccounts";
import SvgSumYearly from "../icons/functions/SumYearly";
import SvgSumLocation from "../icons/functions/SumLocation";
import SvgReports from "../icons/functions/Reports";
import SvgEconomyData from "../icons/functions/EconomyData";
import SvgCosts from "../icons/functions/Costs";
import SvgCostCategory from "../icons/functions/CostCategory";
import SvgMaps from "../icons/functions/Maps";
import SvgManagment from "../icons/functions/Managment";
import SvgSearch from "../icons/functions/Search";
import SvgBasicFunc01 from "../icons/characteristics/BasicFunc01";
import SvgBasicFunc02 from "../icons/characteristics/BasicFunc02";
import SvgBasicFunc03 from "../icons/characteristics/BasicFunc03";

export const appName = "iANOSeNERGY";
export const appNameTitle = (
  <>
    <span style={{ color: "#21AAD4" }}>i</span>ANOS
    <span style={{ color: "#21AAD4" }}>e</span>NERGY
  </>
);
export const basicFunc = <>Η εφαρμογή {appNameTitle} σε νούμερα </>;

export const basicFuncArray = [
  {
    title: "Δήμοι",
    imageUrl: null,
    svgIcon: municipality,
    number: "15",
  },
  {
    title: "Παροχές",
    imageUrl: null,
    svgIcon: provides,
    number: "6.375",
  },
  {
    title: "Σύνολο Ετήσιας Κατανάλωσης (Kwh)",
    imageUrl: null,
    svgIcon: electricity,
    number: "59.864.433",
  },
  {
    title: "Ετήσιο Σύνολο Πληρωμών",
    imageUrl: null,
    svgIcon: payments,
    number: "10.828.358",
  },
  {
    title: "Ανενεργές Παροχές",
    imageUrl: null,
    svgIcon: disabledProvides,
    number: "487",
  },
];

export const setction_1_title = "";
export const setction_1_arrayTitle = "";
export const setction_1_array = [
  "Αναλυτική κατάσταση των λογαριασμών σε επεξεργάσιμη ψηφιακή μορφή",
  "Ανάλυση της κατανάλωσης ενέργειας ανά κέντρο κόστους",
  "Εύκολος εντοπισμός ανενεργών παροχών",
  "Εύκολη παρακολούθηση υψηλών μη αναμενόμενων καταναλώσεων",
];
export const Section_1_svgIcon = SvgBasicFunc01;

export const setction_2_title = "";
export const setction_2_arrayTitle = "";
export const setction_2_array = [
  "Έγκαιρος εντοπισμός πιθανών προβλημάτων",
  "Ενημέρωση για νέες και κατηργημένες συνδέσεις",
  "Ενεργειακό αποτύπωμα του Δήμου",
  "Προβολή σε χαρτογραφικό υπόβαθρο το σύνολο των μετρητών, ιστών κτλ",
];

export const Section_2_svgIcon = SvgBasicFunc02;

export const setction_3_title = "";
export const setction_3_arrayTitle = "";
export const setction_3_array = [
  "Στατιστικά και συγκριτικά στοιχεία καταναλώσεων και πληρωμών",
  "Mobile εφαρμογή διαχείρισης συντηρήσεων ηλεκτρικών εγκαταστάσεων",
  "Εντολές ανάθεσης σε προσωπικό του Δήμου για ηλεκτρολογικές εργασίες",
  "Παρακολούθηση φθορών και εργασιών πεδίου που αφορούν τους λαμπτήρες",
];

export const Section_3_svgIcon = SvgBasicFunc03;

export const setction_4_title =
  "Υποσύστημα πληροφόρησης οδηγών για ελεύθερες θέσεις στάθμευσης και συνθήκες κυκλοφορίας";
export const setction_4_arrayTitle = "Περιλαμβάνει";
export const setction_4_array = [
  "Mobile app για την ενημέρωση των οδηγών σε πραγματικό χρόνο για τις ελεύθερες θέσεις στάθμευσης καθώς και πληρωμής θέσεων παρόδιας στάθμευσης. Εναλλακτικά θα μπορούσε να γίνει χρήση Mobile εφαρμογής μέσω της οποίας ο χρήστης μπορεί να επιβεβαιώσει τη θέση που του προτείνει το σύστημα ως ελεύθερη και εν συνεχεία να πληρώσει από το κινητό του τηλέφωνο (σε αυτή την περίπτωση δεν απαιτείται εγκατάσταση αισθητήρων)",
  "Διαδικτυακή πύλης (web portal) παρουσίασης των ελεύθερων θέσεων στάθμευσης για τους πολίτες με χρήση γεωγραφικών συστημάτων πληροφοριών.",
  "Ηλεκτρονικές Πινακίδες πληροφόρησης οδηγών οι οποίες ενημερώνουν τους οδηγούς για τις ελεύθερες θέσεις στάθμευσης σε διαφορετικούς δρόμους της πόλης.",
  "Ανοικτή διάθεση δεδομένων στάθμευσης και κυκλοφορίας (Open Data API) για χρήση από τρίτα συστήματα.",
];

export const setction_5_title =
  "Δημιουργία ολοκληρωμένης παρέμβασης με εγκατάσταση Κέντρου Διαχείρισης και Παρακολούθησης της Αστικής Κινητικότητας";
export const setction_5_arrayTitle =
  "Περιλαμβάνει το σύνολο του απαραίτητου κεντρικού λογισμικού στο οποίο οι χειριστές μπορούν να παρακολουθούν το σύνολο των υποσυστημάτων, την καθημερινή λειτουργία των αισθητήρων, των καμερών σε πραγματικό χρόνο (online), των παραβάσεων, τις θέσεις των ΑμΕΑ και της φορτοεκφόρτωσης με χρήση ενός ενιαίου γεωγραφικού πληροφοριακού συστήματος. Το Κέντρο Ελέγχου θα παρέχει λειτουργίες διαχείρισης συμβάντων (alerts) και θα είναι προσβάσιμο από συγκεκριμένες κατηγορίες χρηστών.";

export const basicFunc2 = "Βασικά Χαρακτηριστικά της εφαρμογής ";
export const basicFuncArray2 = [
  {
    title: "Αναλυτική κατάσταση των λογαριασμών σε επεξεργάσιμη ψηφιακή μορφή",
    imageUrl: "/layers-active.svg",
  },
  {
    title: "Ανάλυση της κατανάλωσης ενέργειας ανά κέντρο κόστους",
    imageUrl: "/basemap-active.svg",
  },
  {
    title: "Εύκολος εντοπισμός ανενεργών παροχών",
    imageUrl: "/spatial-active.svg",
  },
  {
    title: "Εύκολη παρακολούθηση υψηλών μη αναμενόμενων καταναλώσεων",
    imageUrl: "/download-active.svg",
  },
  {
    title: "Έγκαιρος εντοπισμός πιθανών προβλημάτων",
    imageUrl: "/navigation-active.svg",
  },

  {
    title: "Ενημέρωση για νέες και κατηργημένες συνδέσεις",
    imageUrl: "/layers-active.svg",
  },
  {
    title: "Ενεργειακό αποτύπωμα του Δήμου",
    imageUrl: "/basemap-active.svg",
  },
  {
    title: "Προβολή σε χαρτογραφικό υπόβαθρο το σύνολο των μετρητών, ιστών κτλ",
    imageUrl: "/spatial-active.svg",
  },
  {
    title: "Στατιστικά και συγκριτικά στοιχεία καταναλώσεων και πληρωμών",
    imageUrl: "/download-active.svg",
  },
  {
    title: "Mobile εφαρμογή διαχείρισης συντηρήσεων ηλεκτρικών εγκαταστάσεων",
    imageUrl: "/navigation-active.svg",
  },

  {
    title:
      "Εντολές ανάθεσης σε προσωπικό του Δήμου για ηλεκτρολογικές εργασίες",
    imageUrl: "/spatial-active.svg",
  },
  {
    title:
      "Παρακολούθηση φθορών και εργασιών πεδίου που αφορούν τους λαμπτήρες",
    imageUrl: "/download-active.svg",
  },
];

export const imgContent = [
  {
    title: "Γενικές Πληροφορίες",
    svgIcon: SvgGeneralInformation,
    content:
      "Αναφέρεται σε πληροφορίες που ενημερώνουν για τα βασικά στοιχεία όπως: προβολές για τους πολλαπλούς λογαριασμούς, τις κατηγορίες τιμολογίων, σύνολο παροχών (δήμου, πολλαπλού, τύπου τιμολογίου), παροχές που τιμολογούνται ανά μήνα και πληροφορίες κόστους των ανενεργών παροχών.",
  },
  {
    title: "Γρήγορη Ενημέρωση",
    svgIcon: SvgFastInformation,
    content:
      "Παρουσίαση των στοιχείων του δήμου σε σύνολα ανά έτος ή μήνα. Δίνεται μια συνολική εικόνα της κατανάλωσης από πλευράς κόστους, εξέλιξης του αριθμού των μετρητών (ενεργών και μη) και παροχών ανά τύπο τιμολογίου.",
  },
  {
    title: "Αρχείο Λογαριασμών",
    svgIcon: SvgArchiveAccounts,
    content:
      "Περιλαμβάνει λίστες εγγραφών ταξινομημένες ανά μήνα, ανά πολλαπλό, ανά τιμολόγιο και συνδυασμό αυτών. Ακόμη, παρουσιάζονται οι ανενεργές παροχές ανά τύπο τιμολογίου και ανά κατηγορία.",
  },
  {
    title: "Σύνολα Έτους",
    svgIcon: SvgSumYearly,
    content:
      "Στην ενότητα αυτή παρουσιάζονται τα σύνολα ανά έτος και σε επίπεδο χρόνου και μήνα. Η πληροφορίες αφορούν κυρίως το πληρωτέο ποσό και το σύνολο του τρέχοντος λογαριασμού για κάθε τύπο τιμολογίου, αριθμό πολλαπλού αναγράφοντας αναλυτικά τα στοιχεία των λογαριασμών (Kwh, πάγια χρέωση, αξία ενέργειας, αξία ισχύος, κτλ).",
  },
  {
    title: "Σύνολα ανά Παροχή",
    svgIcon: SvgSumLocation,
    content:
      "Περιλαμβάνει εντολές οι οποίες κατατάσσουν το σύνολο κατανάλωσης πληρωμών κτλ ανά κατηγορία με τον τρόπο που θα επιλέξουμε εμείς .Για το τρέχον έτος φαίνονται τα αποτελέσματα που είναι καταχωρημένα μέχρι την ημερομηνία αναζήτησης του αποτελέσματος.",
  },
  {
    title: "Αναφορές",
    svgIcon: SvgReports,
    content:
      "Αναφέρεται σε ειδικευμένες πληροφορίες που μας επιτρέπουν να ενημερωθούμε για παραμέτρους που μπορεί να μειώσουν το κόστος των λογαριασμών. Ακόμα, μπορούν να ελεγχθούν τυχόν λάθη και να διαπιστωθεί αν ο πάροχος ηλεκτρικής ενέργεια τηρεί τα συμφωνηθέντα.",
  },
  {
    title: "Οικονομικά Δεδομένα",
    svgIcon: SvgEconomyData,
    content:
      "Παρουσίαση συγκεντρωτικών και αναλυτικών εγγραφών με το κόστος κάθε έτους ομαδοποιημένα ανά μήνα, τιμολόγιο, δημοτικό διαμέρισμα κτλ. Ακόμη, αναφέρονται τα κόστη και χρεώσεις δημοτικών τελών, εκτέλεσης εργασιών, ανενεργών παροχών και πάγιων χρεώσεων. Απευθύνεται στην οικονομική υπηρεσία του δήμου.",
  },
  {
    title: "Θεματικές Λίστες Κόστους",
    svgIcon: SvgCosts,
    content:
      "Παρουσίαση αριθμού παροχών και πολλαπλών ανάλογα με τον τύπο των εγκαταστάσεων που ανήκουν (φωτισμός, αγροτικές γεωτρήσεις, αντλιοστάσια ύδρευσης, βιολογικοί καθαρισμοί, κτιριακές εγκαταστάσεις, αθλητικές εγκαταστάσεις κτλ)",
  },
  {
    title: "Κόστος ανά κατηγορία",
    svgIcon: SvgCostCategory,
    content:
      "Συνολικά κόστη ανά έτος των καταναλώσεων του δήμου ανάλογα με τον τύπο παροχής. Απευθύνεται στην οικονομική υπηρεσία για την καλύτερη εποπτεία του κόστους αυτών των τιμολογίων.",
  },

  {
    title: "Χάρτες",
    svgIcon: SvgMaps,
    content:
      "Παρουσίαση όλων των μετρητών στο χάρτη για την πλήρη καταγραφή τους και την άμεση εύρεση της τοποθεσίας τους. Ιδιαίτερα χρήσιμο για την εύρεση ανενεργών παροχών και παροχών ΦΟΠ.",
  },
  {
    title: "Διαχείριση",
    svgIcon: SvgManagment,
    content:
      "Περιλαμβάνει τη διαχείριση χρηστών, τύπων και χρήσεων μετρητών και κατηγοριών δαπανών",
  },
  {
    title: "Αναζήτηση",
    svgIcon: SvgSearch,
    content:
      "Μπορούμε να έχουμε συγκεκριμένες πληροφορίες για ένα αριθμό παροχής η για έναν αριθμό μετρητή η ακόμη το σύνολο των λογαριασμών που κατατάσσονται σε έναν πολλαπλό λογαριασμό.",
  },
];
