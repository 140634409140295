import * as React from "react";

const SvgPayments = (props) => (
  <svg
    id="Payments_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300.29 270.24"
    {...props}
  >
    <defs>
      <style>{".Payments_svg__cls-1{fill:#2d4c6b}"}</style>
    </defs>
    <path
      className="Payments_svg__cls-1"
      d="M27.13 216.34V47a16.32 16.32 0 0 1 16.3-16.29h169.39A16.31 16.31 0 0 1 229.11 47v83.3h8.51V47a24.83 24.83 0 0 0-24.8-24.8H43.43A24.83 24.83 0 0 0 18.63 47v169.34a24.83 24.83 0 0 0 24.8 24.8h107.72v-8.51H43.43a16.32 16.32 0 0 1-16.3-16.29Z"
    />
    <path
      className="Payments_svg__cls-1"
      d="M106.79 86.27a2.84 2.84 0 0 0-2.79-2.84H91V70.38a2.84 2.84 0 0 0-5.67 0v13.05h-13a2.84 2.84 0 0 0 0 5.67h13v13.05a2.84 2.84 0 0 0 5.67 0V89.1h13a2.84 2.84 0 0 0 2.79-2.83ZM182.56 86.27a2.84 2.84 0 0 0-2.83-2.84h-32.17a2.84 2.84 0 1 0 0 5.67h32.17a2.83 2.83 0 0 0 2.83-2.83ZM168.25 152.19l-7.37 31.11a2.83 2.83 0 0 0 2.11 3.41 2.46 2.46 0 0 0 .65.08 2.84 2.84 0 0 0 2.76-2.18l7.37-31.11a2.84 2.84 0 0 0-5.52-1.31ZM101 150.59a2.84 2.84 0 0 0-3.88 1L90.62 163l-6.54-11.33a2.83 2.83 0 0 0-4.91 2.83l8.18 14.17-8.18 14.16a2.84 2.84 0 1 0 4.91 2.84l6.54-11.33 6.54 11.33a2.84 2.84 0 0 0 2.46 1.42 2.94 2.94 0 0 0 1.42-.38 2.84 2.84 0 0 0 1-3.88l-8.18-14.16 8.18-14.17a2.83 2.83 0 0 0-1.04-3.91ZM155.57 225.8h19.38v5.67h-19.38zM161.32 242.42h19.38v5.67h-19.38zM188.72 242.42h6.52v5.67h-6.52zM231.39 146.59a50.27 50.27 0 1 0 50.27 50.27 50.33 50.33 0 0 0-50.27-50.27Zm0 92a41.77 41.77 0 1 1 41.77-41.77 41.82 41.82 0 0 1-41.77 41.81Z"
    />
    <path
      className="Payments_svg__cls-1"
      d="M231.47 183.07c1.62-1.38 4-2.08 7-2.08a14.9 14.9 0 0 1 5.56 1l1.43-9a32.93 32.93 0 0 0-7.72-1q-9.12 0-14.67 4.63t-6.19 12.87h-5.59v5.32h5.49v4h-5.49v5.32h5.49q.27 8.22 5.61 12.88t14.32 4.65a39.43 39.43 0 0 0 8.75-1l-1.43-9a16 16 0 0 1-5.86 1c-3.39 0-5.83-.69-7.3-2.09s-2.27-3.54-2.38-6.46h10.38v-5.32h-10.36v-4h10.38v-5.32h-10.28q.39-4.31 2.86-6.4Z"
    />
  </svg>
);

export default SvgPayments;
