import React from "react";

import Header from "./components/header";
import Content from "../src/components/content";
import Wrapper from "./components/wrapper";
import Contact from "../src/components/contact";
import Clients from "./components/clients";
import BottomUp from "./components/bottomUp";
import Recommended from "./components/Recommended";
import {
  appNameTitle,
  basicFunc,
  basicFunc2,
  basicFuncArray,
  basicFuncArray2,
} from "./data/staticData";
import GalleryDefault from "./components/UI/GalleryDefault";
import Description from "./components/description";

const Main = () => {
  return (
    <div className="content-container">
      <Header />
      <Content />
      <Wrapper title={basicFunc} gridList={basicFuncArray} />

      <Description title={basicFunc2} />

      <div
        className="functionality-content maxWidth__class"
        id="functionality"
        style={{ marginTop: 40 }}
      >
        <h2 style={{ marginBottom: "40px" }}>
          Βασικές λειτουργίες της εφαρμογής {appNameTitle}
        </h2>
        {/*<p*/}
        {/*  style={{ marginInline: "150px", marginTop: "50px", fontSize: "17px" }}*/}
        {/*>*/}
        {/*  Η εφαρμογή της διαχείρισης και παρακολούθησης του κόστους ηλεκτρικής*/}
        {/*  ενέργειας του κάθε Δήμου διαθέτει μενού πλοήγησης για την εύκολη*/}
        {/*  μετάβαση του χρήστη στις κατάλληλες πληροφορίες. Το σύστημα*/}
        {/*  περιλαμβάνει τις ενότητες:*/}
        {/*</p>*/}
        {/*<GalleryDefault />*/}
        <Recommended hoverEffect />
      </div>

      {/*<Wrapper*/}
      {/*  title={basicFunc2}*/}
      {/*  gridList={basicFuncArray2}*/}
      {/*  boxSize={"large"}*/}
      {/*/>*/}

      {/*<div*/}
      {/*  className="functionality-content maxWidth__class energy"*/}
      {/*  id="energy"*/}
      {/*  style={{ marginTop: 40 }}*/}
      {/*>*/}
      {/*  <h2 style={{ marginBottom: "40px" }}>Ενεργειακή Παρακολούθηση</h2>*/}
      {/*  /!*<p*!/*/}
      {/*  /!*  style={{ marginInline: "150px", marginTop: "50px", fontSize: "17px" }}*!/*/}
      {/*  /!*>*!/*/}
      {/*  /!*  Η εφαρμογή της διαχείρισης και παρακολούθησης του κόστους ηλεκτρικής*!/*/}
      {/*  /!*  ενέργειας του κάθε Δήμου διαθέτει μενού πλοήγησης για την εύκολη*!/*/}
      {/*  /!*  μετάβαση του χρήστη στις κατάλληλες πληροφορίες. Το σύστημα*!/*/}
      {/*  /!*  περιλαμβάνει τις ενότητες:*!/*/}
      {/*  /!*</p>*!/*/}
      {/*  /!*<GalleryDefault />*!/*/}
      {/*  /!*<Recommended hoverEffect />*!/*/}
      {/*</div>*/}

      <Clients />

      <Contact />
      <BottomUp />
    </div>
  );
};

export default Main;
