import React from "react";
import "../css/clients.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { useTranslation } from "react-i18next";
import SvgClient01 from "../icons/clients/Client01";
import SvgClient02 from "../icons/clients/Client02";
import SvgClient03 from "../icons/clients/Client03";
import SvgClient04 from "../icons/clients/Client04";
import SvgClient05 from "../icons/clients/Client05";
import SvgClient06 from "../icons/clients/Client06";
import SvgClient07 from "../icons/clients/Client07";
import SvgClient08 from "../icons/clients/Client08";
import SvgClient09 from "../icons/clients/Client09";
import SvgClient10 from "../icons/clients/Client10";
import SvgClient11 from "../icons/clients/Client11";
import SvgClient12 from "../icons/clients/Client12";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 6, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
};

const Clients = () => {
  const { t } = useTranslation();
  return (
    <div className="clients-container">
      <h2>{t("Πελάτες")}</h2>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={responsive}
        autoPlaySpeed={3500}
        autoPlay={true}
        infinite={true}
      >
        <SvgClient01 />
        <SvgClient02 />
        <SvgClient03 />
        <SvgClient04 />
        <SvgClient05 />
        <SvgClient06 />
        <SvgClient07 />
        <SvgClient08 />
        <SvgClient09 />
        <SvgClient10 />
        <SvgClient11 />
        <SvgClient12 />
      </Carousel>
    </div>
  );
};
export default Clients;
