import React from "react";

import Header from "./header_page";
import Contact from "./contact_page";

import "./../css/zitsa.css";

class Zitsa extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main">
          <div className="card">
            <div className="card_ins"></div>
            <div className="text">
              Παρακολούθηση δεδομένων [ανάλυση, ανασκόπηση, αναφορές]
            </div>
          </div>
          <div className="card">
            <div className="card_ins"></div>
            <div className="text">dfdfdffd</div>
          </div>
          <div className="card">
            <div className="card_ins"></div>
            <div className="text">dfdfdffd</div>
          </div>
        </div>
        <Contact />
        {/* <div className="footer">
                    <div style={{ float: 'right', paddingTop: 6 + 'px', marginRight: 10 + 'px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', height: 19 + 'px' }}>
                            <div style={{ height: 19 + 'px', fontSize: 14 + 'px', lineHeight: 1.2, marginRight: 5 + 'px' }}>
                                <span style={{ height: 19 + 'px', fontSize: 14 + 'px' }}>Powered by</span>
                            </div>
                            <div style={{ height: 19 + 'px' }}>
                                <img height="100%" src="https://dotsoft.gr/sites/default/files/logo.png"></img>
                            </div>
                        </div>
                    </div>
                    <div style={{ fontSize: 14 + 'px', paddingTop: 6 + 'px', marginLeft: 10 + 'px' }}>©</div>
                </div> */}
      </React.Fragment>
    );
  }
}

export default Zitsa;
