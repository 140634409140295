import * as React from "react";

const SvgMunicipality = (props) => (
  <svg
    id="Municipality_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300.29 270.24"
    {...props}
  >
    <defs>
      <style>{".Municipality_svg__cls-1{fill:#2d4c6b}"}</style>
    </defs>
    <path
      className="Municipality_svg__cls-1"
      d="M54.26 133.67H246a9.91 9.91 0 0 0 7-16.91L162.77 26.5a17.86 17.86 0 0 0-25.25 0l-90.26 90.26a9.91 9.91 0 0 0 7 16.91ZM270 240.46h-23.86v-85a4.25 4.25 0 0 0-8.5 0v85H185v-57.08a4.25 4.25 0 0 0-8.5 0v57.08h-52.68v-57.08a4.26 4.26 0 0 0-8.51 0v57.08H62.66v-85a4.26 4.26 0 0 0-8.51 0v85h-23.8a4.26 4.26 0 1 0 0 8.51H270a4.26 4.26 0 0 0 0-8.51Z"
    />
  </svg>
);

export default SvgMunicipality;
