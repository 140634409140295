import * as React from "react";

const SvgElectricity = (props) => (
  <svg
    id="Electricity_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300.29 270.24"
    {...props}
  >
    <defs>
      <style>{".Electricity_svg__cls-1{fill:#2d4c6b}"}</style>
    </defs>
    <path
      className="Electricity_svg__cls-1"
      d="M247.73 46.9v137.39h8.5V46.9a24.83 24.83 0 0 0-24.8-24.8H62.05a24.83 24.83 0 0 0-24.81 24.8v169.38a24.83 24.83 0 0 0 24.81 24.8H132v-8.5H62.05a16.32 16.32 0 0 1-16.3-16.3V46.9a16.32 16.32 0 0 1 16.3-16.3h169.38a16.32 16.32 0 0 1 16.3 16.3Z"
    />
    <path
      className="Electricity_svg__cls-1"
      d="M125.4 86.21a2.84 2.84 0 0 0-2.84-2.83h-13v-13a2.84 2.84 0 1 0-5.67 0v13h-13a2.84 2.84 0 1 0 0 5.67h13v13a2.84 2.84 0 1 0 5.67 0v-13h13a2.84 2.84 0 0 0 2.84-2.84ZM201.18 86.21a2.84 2.84 0 0 0-2.84-2.83h-32.17a2.84 2.84 0 0 0 0 5.67h32.17a2.84 2.84 0 0 0 2.84-2.84ZM186.86 152.14l-7.36 31.11a2.83 2.83 0 0 0 2.1 3.41 3 3 0 0 0 .66.08 2.83 2.83 0 0 0 2.75-2.19l7.37-31.1a2.84 2.84 0 1 0-5.52-1.31ZM119.65 150.53a2.84 2.84 0 0 0-3.87 1l-6.55 11.33-6.54-11.33a2.84 2.84 0 1 0-4.91 2.84l8.22 14.2-8.18 14.17a2.83 2.83 0 0 0 1 3.87 2.79 2.79 0 0 0 1.41.38 2.83 2.83 0 0 0 2.46-1.42l6.54-11.33 6.55 11.33a2.83 2.83 0 0 0 4.91-2.83l-8.18-14.17 8.18-14.16a2.85 2.85 0 0 0-1.04-3.88ZM184.73 202.4h-9.61l-13.39 16.02-4.39 5.43V202.4h-7.95v45.74h7.95v-14.26l5.18-5.5 13.76 19.76h9.42l-17.97-25.48 17-20.26z"
    />
    <path
      className="Electricity_svg__cls-1"
      d="m217.09 237.62-7.07-23.47h-5.75l-6.97 23.21-5.5-23.21h-7.45l9.3 33.99h6.29l7.16-23.34 7.29 23.34h6.28l9.27-33.99h-7.45l-5.4 23.47zM252 213.52a11.93 11.93 0 0 0-9.45 4.33v-18h-7.64v48.26h7.64v-24.25a7.34 7.34 0 0 1 6.85-4.09 6.23 6.23 0 0 1 4.47 1.46q1.53 1.47 1.53 5v21.93h7.63v-22.23q-.14-12.41-11.03-12.41Z"
    />
  </svg>
);

export default SvgElectricity;
